import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import HeaderDash from '../HeaderDashNew';
import Footer from '../Footer';
import './Team.css'; // Ensure correct CSS import
import UserAuthContext from '../../../context/UserAuthContext';

interface Downline {
  user_name: string;
  wallet_address: string;
  joined_at: string;
}

const Team: React.FC = () => {
  const { userData } = useContext(UserAuthContext);
  const [downlines, setDownlines] = useState<Downline[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedZone, setSelectedZone] = useState<string>('premium'); // Default zone

  const fetchTeamData = async (userId: number, zone: string) => {
    try {
      const response = await axios.get(
        `http://rainmakernew.gamingpandastudios.com/fetchTeams.php`,
        {
          params: {
            user_id: userId,
            zone: zone,
          },
        }
      );
      setDownlines(response.data.downlines);
    } catch (error) {
      console.error('Error fetching team data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(userData);
    if (userData?.id) {
      fetchTeamData(Number(userData.id), selectedZone);
    }
  }, [userData, selectedZone]);

  const handleZoneChange = (zone: string) => {
    setSelectedZone(zone);
    setLoading(true); // Show loading during data fetch
  };

  if (loading) {
    return <div className="team-loading">Loading team data...</div>;
  }

  return (
    <div className="team-page">
      <HeaderDash />
      <div className="team-main-content">
        <h1>Your Team</h1>
        {/* <div className="team-zone-toggle">
          <button
            className={`team-zone-button ${selectedZone === 'premium' ? 'active' : ''}`}
            onClick={() => handleZoneChange('premium')}
          >
            Premium Zone
          </button>
          <button
            className={`team-zone-button ${selectedZone === 'rainmaker' ? 'active' : ''}`}
            onClick={() => handleZoneChange('rainmaker')}
          >
            Rainmaker Zone
          </button>
        </div> */}

<div className="team-table-wrapper">
  <table className="team-table">
    <thead>
      <tr>
        <th>Username</th>
        <th>Email Address</th>
        <th>Downlines</th>
        <th>Direct Referrals</th>
      </tr>
    </thead>
    <tbody>
      {userData?.direct_referrals.map((downline, index) => (
        <tr key={index}>
          <td>{downline.user_name}</td>
          <td>{downline.email}</td>
          <td>{downline.direct_team}</td>
          <td>{downline.team_count}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>
      </div>
      <Footer />
    </div>
  );
};

export default Team;