import React, { useEffect } from 'react';
import './CongratulationsModal.css';

interface CongratulationsModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const CongratulationsModal: React.FC<CongratulationsModalProps> = ({ isOpen, onRequestClose }) => {
  useEffect(() => {
    if (isOpen) {
      console.log("CongratulationsModal is open"); // Debugging log
      const timer = setTimeout(() => {
        onRequestClose(); // Auto-close the modal after 3 seconds
      }, 3000);

      return () => clearTimeout(timer); // Clean up timer on unmount
    }
  }, [isOpen, onRequestClose]);

  if (!isOpen) return null;

  return (
    <div className="congrats-modal-overlay">
  <div className="congrats-modal-content">
    <div className="checkmark-animation">
      <img src={require('../assets/images/check1.webp')} alt="Verified" />
    </div>
    <h2 className = "congrats">Congratulations!</h2>
    <p>You have successfully activated.</p>

    <div className="confetti-container">
      <div className="confetti"></div>
      <div className="confetti"></div>
      <div className="confetti"></div>
      <div className="confetti"></div>
      <div className="confetti"></div>
      <div className="confetti"></div>
    </div>
  </div>
</div>

  );
};

export default CongratulationsModal;