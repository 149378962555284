import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import "./Modal.css";
import Toastify from "toastify-js";
import { getWeb3, getContract, getBNBBalance } from '../../../Web3Utils';
import Web3Context from "../../../context/Web3Context";
import Web3 from 'web3';
import CongratulationsModal from '../../CongratulationsModal'; // Import CongratulationsModal
import axios from "axios";
import ENDPOINTS from "../../../endpoints";

// SmartRainmakers contract address
const contractAddress = "0x9260D921008ab4d41A0AFca63cdf792F2D578880"; 

interface TransferModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  username: string;
  userAddress: string;
  userBalance: number;
  payoutBalance: number;
  userId: string | null;
  sponsorId: string | null;
  onConfirmTransfer: () => void;
  setTransferAmount: React.Dispatch<React.SetStateAction<number | null>>;
}

interface OperationPayload {
  from_user_id: number,
  to_user_id: number, // Use recipient ID
  amount: number,
}


Modal.setAppElement('#root');

const TransferModal: React.FC<TransferModalProps> = ({
  isOpen,
  onRequestClose,
  username,
  userAddress,
  userBalance,
  payoutBalance,
  userId,
  sponsorId,
  onConfirmTransfer,
  setTransferAmount,
}) => {
  // Context and states
  const { web3, account: userAdd, isConnected, connectWallet, initWeb3Manually, setWeb3, setAccount } = useContext(Web3Context);
  const [toUser, setToUser] = useState("");
  const [contract, setContract] = useState<any>(null);
  const [transferAmount, localSetTransferAmount] = useState<number | string>(0);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [selectedRecipient, setSelectedRecipient] = useState<any | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bnbBalance, setBNBBalance] = useState<string>('0'); // State to hold BNB balance
  const [isCongratulationsModalOpen, setIsCongratulationsModalOpen] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState("wallet_balance");

  // Modal open/close lifecycle
  useEffect(() => {
    if (isOpen) {
      setToUser("");
      localSetTransferAmount(0);
      setSearchResults([]);
      setSelectedRecipient(null);
    }
  }, [isOpen]);

  // Initialize contracts and balances
  const initializeContracts = async (web3Instance: Web3) => {
    try {
      const contractInstance = getContract(web3Instance, contractAddress);
      setContract(contractInstance);
    } catch (error) {
      console.error("Contract initialization error:", error);
      Toastify({
        text: "Failed to initialize contracts. Please try again.",
        duration: 3000,
        gravity: "top",
        position: "center",
        style: { background: "linear-gradient(to right, #FF0000, #CB4335)" },
      }).showToast();
    }
  };

  const fetchBalances = async (web3Instance: Web3, walletAddress: string) => {
    if (web3Instance && walletAddress) {
      try {
        const bnb = await getBNBBalance(web3Instance, walletAddress);
        const bnbFormatted = parseFloat(bnb).toFixed(2);
        setBNBBalance(bnbFormatted);
        console.log("BNB wallet balance:", bnbFormatted);
      } catch (error) {
        console.error("Error fetching balances:", error);
      }
    } else {
      console.error("Web3 or wallet address is missing.");
    }
  };

  // Initialize Web3 for email/password login
  useEffect(() => {
    const initWeb3ForEmailPassword = async () => {
      if (!isConnected && userAddress) {
        try {
          const web3Instance = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545/');
          setWeb3(web3Instance);
          setAccount(userAddress);
          await initializeContracts(web3Instance);
          fetchBalances(web3Instance, userAddress);
        } catch (error) {
          console.error("Error initializing Web3 for email_password login:", error);
        }
      }
    };

    if (!isConnected && userAddress) {
      initWeb3ForEmailPassword();
    }
  }, [userAddress, isConnected, setWeb3, setAccount]);

  // Search for users
  const handleSearch = async () => {
    if (!toUser.trim()) {
      Toastify({
        text: "Please enter a username or wallet address to search!",
        duration: 3000,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
      return;
    }

    try {
      setIsSearching(true);
  
      const response = await axios.post(ENDPOINTS.searchUser, {
        search_value: toUser,
      }, {
        headers: { "Content-Type": "application/json" },
      });
  
      if (response.data.user) {
        setSearchResults([response.data.user]);
      } else {
        setSearchResults([]);
        Toastify({
          text: response.data.message || "User not found!",
          duration: 3000,
          gravity: "top",
          position: "center",
          style: {
            background: "linear-gradient(to right, #FF0000, #CB4335)",
          },
        }).showToast();
      }
    } catch (error) {
      console.error("Error searching user:", error);
      Toastify({
        text: "An error occurred while searching for the user.",
        duration: 3000,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
    } finally {
      setIsSearching(false);
    }
  };

  const handleUserSelect = (user: any) => {
    setSelectedRecipient(user);
    setToUser(user.user_name);
    setSearchResults([]);
  };

  // Transfer logic
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = e.target.value === "" ? "" : Number(e.target.value);
    localSetTransferAmount(amount);
    setTransferAmount(amount || null);
  };

  const handleMaxTransfer = () => {
    const maxAmount = selectedWallet === "wallet_balance" ? userBalance : payoutBalance ?? 0;
    localSetTransferAmount(maxAmount);
    setTransferAmount(userBalance);
  };

  const handleConfirm = async () => {
    if (!web3 || !userAdd) {
      Toastify({
        text: "Wallet not connected.",
        duration: 3000,
        gravity: "top",
        position: "center",
        style: { background: "linear-gradient(to right, #FF0000, #CB4335)" },
      }).showToast();
      return;
    }

    if (!selectedRecipient?.wallet_address) {
      Toastify({
        text: "Recipient address is required.",
        duration: 3000,
        gravity: "top",
        position: "center",
        style: { background: "linear-gradient(to right, #FF0000, #CB4335)" },
      }).showToast();
      return;
    }

    const recipientAddress = selectedRecipient.wallet_address;

    if (!transferAmount || Number(transferAmount) <= 0 || (Number(transferAmount) > userBalance && selectedWallet === "wallet_balance")) {
      Toastify({
        text: "Invalid transfer amount.",
        duration: 3000,
        gravity: "top",
        position: "center",
        style: { background: "linear-gradient(to right, #FF0000, #CB4335)" },
      }).showToast();
      return;
    }

    setIsLoading(true);

    try {
      // const gasPrice = await web3.eth.getGasPrice();
      // const gasEstimate = await contract.methods
      //   .transfer(recipientAddress, web3.utils.toWei(transferAmount.toString(), "ether"))
      //   .estimateGas({ from: userAdd });

      // await contract.methods
      //   .transfer(recipientAddress, web3.utils.toWei(transferAmount.toString(), "ether"))
      //   .send({
      //     from: userAdd,
      //     gas: (Number(gasEstimate) + 20000).toString(),
      //     gasPrice: gasPrice.toString(),
      //   })
      //   .on("transactionHash", (hash: string) => {
      //     console.log("Transaction hash:", hash);
      //     Toastify({
      //       text: `Transaction submitted! Hash: ${hash}`,
      //       duration: 5000,
      //       gravity: "top",
      //       position: "center",
      //       style: { background: "linear-gradient(to right, #28a745, #218838)" },
      //     }).showToast();
      //   })
      //   .on("receipt", (receipt: any) => {
      //     console.log("Transaction confirmed:", receipt);
      //     Toastify({
      //       text: "Transfer successful!",
      //       duration: 3000,
      //       gravity: "top",
      //       position: "center",
      //       style: { background: "linear-gradient(to right, #28a745, #218838)" },
      //     }).showToast();
      //     onConfirmTransfer();
      //     onRequestClose();
      //   })
      //   .on("error", (error: any) => {
      //     console.error("Transaction failed:", error);
      //     Toastify({
      //       text: "Transaction failed. Please try again.",
      //       duration: 3000,
      //       gravity: "top",
      //       position: "center",
      //       style: { background: "linear-gradient(to right, #FF0000, #CB4335)" },
      //     }).showToast();
      //   });


// Call OPeration API =====================//

          if (!userId || !sponsorId) {
            console.error("User data is missing!");
            showErrorToast("User data not found. Please try again.");
            setIsLoading(false);
            return;
          }

          // Show the transaction hash to the user
          Toastify({
            text: `Transferred Successfully`,
            duration: 5000,
            gravity: "top",
            position: "center",
            style: { background: "linear-gradient(to right, #28a745, #218838)" },
          }).showToast();
          console.log("From User", userId);
          console.log("To User",selectedRecipient.id);
          console.log("Amount", transferAmount);
          console.log("User Balance", userBalance);


        // Prepare the payload for the API
        // const payload: OperationPayload = {
        //   from_user_id: userId,
        //   to_user_id: selectedRecipient.id, // Use recipient ID
        //   amount: Number(transferAmount),
        // };

        // Call the transferAmount API
        await callOperationAPI();


      // Open the "Congratulations" modal after 3 seconds
      setTimeout(() => {
        onConfirmTransfer();
      }, 3000);

      // Automatically close both modals after showing the Congratulations modal
      setTimeout(() => {
        setIsCongratulationsModalOpen(false); // Close the Congratulations modal
        onRequestClose(); // Close the Transfer modal
      }, 6000); //

    } catch (error) {
      console.error("Error during transfer:", error);
      Toastify({
        text: "An error occurred during transfer. Please try again.",
        duration: 3000,
        gravity: "top",
        position: "center",
        style: { background: "linear-gradient(to right, #FF0000, #CB4335)" },
      }).showToast();
    } finally {
      setIsLoading(false);
    }
  };


  const callOperationAPI = async (): Promise<void> => {
    try {
      console.log("Sending data to operationAPI:", {
        from_user_id: userId,
        to_user_id: selectedRecipient._id, // Use recipient ID
        amount: Number(transferAmount),
        is_payout: selectedWallet === "wallet_balance" ? false : true,
      });
  
      const response = await axios.post(ENDPOINTS.transferAmount, {
        from_user_id: userId,
        to_user_id: selectedRecipient._id, // Use recipient ID
        amount: Number(transferAmount),
        is_payout: selectedWallet === "wallet_balance" ? false : true,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      console.log("Transfer API Response:", response.data);
  
      if (response.data.status === 'success') { 
        console.log('Transfer API completed successfully:', response.data);
        // alert("Transfer successful!");
      } else {
        console.error('Transfer failed:', response.data.error || 'Unknown error');
        // alert(`Transfer failed: ${response.data.error || 'Unknown error'}`);
      }
    } catch (error) {
      console.error("Transfer API call failed:", error);
      // alert("An error occurred while processing the transfer.");
    }
  };

    

  // const showSuccessToast = (message: string) => {
  //   Toastify({
  //     text: message,
  //     duration: 3000,
  //     gravity: 'top',
  //     position: 'center',
  //     backgroundColor: "linear-gradient(to right, #28a745, #218838)",
  //   }).showToast();
  // };
  
  const showErrorToast = (message: string) => {
    Toastify({
      text: message,
      duration: 5000,
      gravity: 'top',
      position: 'center',
      backgroundColor: "linear-gradient(to right, #FF5F6D, #FFC371)",
    }).showToast();
  };
  
  // const showProcessingToast = (message: string) => {
  //   Toastify({
  //     text: message,
  //     duration: 5000,
  //     gravity: 'top',
  //     position: 'center',
  //     backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
  //   }).showToast();
  // };



  const handleCloseCongratulationsModal = () => {
    setIsCongratulationsModalOpen(false);
    onRequestClose();
  };

  // Render
  if (!isOpen) return null;

  return (

    <>

    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Transfer Modal"
      className="transfer-modal"
    >
      <h2 className="modal-title">Send</h2>
      <div className="modal-content">
        {/* From Section */}
        <div className="transfer-section from-section">
          <label className="section-label">From</label>
          <div className="from-box">
            <div className="from-details">
              <div className="from-username">{username}</div>
              <div className="from-address">{userAddress.slice(0, 6)}........{userAddress.slice(-5)}</div>
            </div>

            {/* Wallet Selection Dropdown */}
        <div className="wallet-selection">
          <label className="wallet-label">Select Wallet</label>
          <select
            className="wallet-dropdown"
            value={selectedWallet}
            onChange={(e) => setSelectedWallet(e.target.value)}
          >
            <option value="wallet_balance">Wallet Balance ({userBalance} $)</option>
            <option value="payout_wallet">Payout Wallet ({payoutBalance ?? "0.00"} $)</option>
          </select>
        </div>

            <div className="amount-box">
              <input
                type="number"
                value={transferAmount}
                onChange={handleAmountChange}
                placeholder="Enter amount"
                className="amount-input"
              />
              <div className="balance-text">
              Balance: {selectedWallet === "wallet_balance" ? userBalance : payoutBalance ?? "0.0000"} $
              <span className="max-text" onClick={handleMaxTransfer}>
                  (MAX)
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* To Section */}
        <div className="transfer-section to-section">
          <label className="section-label">To</label>
          {selectedRecipient ? (
            <div className="to-box">
              <div className="to-details">
                <div className="to-username">{selectedRecipient.user_name}</div>
                <div className="to-address">{selectedRecipient.wallet_address.slice(0, 6)}...{selectedRecipient.wallet_address.slice(-4)}</div>
              </div>
              <div className="balance-text">
                Balance: {selectedRecipient.payout_wallet ?? "0.0000"} $
              </div>
            </div>
          ) : (
            <>
              <div className="search-box">
                <input
                  type="text"
                  value={toUser}
                  onChange={(e) => setToUser(e.target.value)}
                  placeholder="Search user or enter username"
                  className="search-input"
                />
                <span className="search-icon" onClick={handleSearch}>
                  🔍
                </span>
              </div>
              {searchResults.length > 0 && (
                <div className="search-results">
                  {searchResults.map((user) => (
                    <div
                      key={user.id}
                      className="search-result-item"
                      onClick={() => handleUserSelect(user)}
                    >
                      <span>{user.user_name}</span> 
                      <br></br> 
                      <span>{user.email}</span> 
                      <br></br> 
                      <span>{user.wallet_address.slice(0, 6)}........{user.wallet_address.slice(-5)}</span>
                    </div>
                  ))}
                </div>
              )}
              {isSearching && <div className="loading-text">Searching...</div>}
            </>
          )}
        </div>

        {/* Action Buttons */}
        <div className="transfer-action-buttons">
          <button className="cancel-button" onClick={onRequestClose}>
            Cancel
          </button>
          <button className="continue-button" onClick={handleConfirm} disabled={isLoading}>
            {isLoading ? "Processing..." : "Continue"}
          </button>
        </div>
      </div>
    </Modal>


    <CongratulationsModal 
        isOpen={isCongratulationsModalOpen}
        onRequestClose={handleCloseCongratulationsModal}
      />

    </>
    
  );
};

export default TransferModal;