// Define base URLs
const BASE_URL_USER = 'https://smartrainmakers.com/api';
// const BASE_URL_USER = 'http://localhost:4000/api';

// Define endpoints
const ENDPOINTS = {
  login: `${BASE_URL_USER}/login`,
  register: `${BASE_URL_USER}/register`,
  getUserDetails: `${BASE_URL_USER}/user-details`,
  changePassword: `${BASE_URL_USER}/change-password`,
  fetchNotifications: `${BASE_URL_USER}/notifications`,
  updateNotificationStatus: `${BASE_URL_USER}/update-notification-status`,
  generateOTP: `${BASE_URL_USER}/generate-otp`,
  verifyOTP: `${BASE_URL_USER}/verify-otp`,
  forgotPassword: `${BASE_URL_USER}/forgot-password`,
  updateProfile: `${BASE_URL_USER}/update-profile`,
  operation: `${BASE_URL_USER}/operation`,
  searchUser: `${BASE_URL_USER}/search-user`,
  transferAmount: `${BASE_URL_USER}/transfer-amount`,
  withdraw: `${BASE_URL_USER}/withdraw`,
  transactions: `${BASE_URL_USER}/transactions`,
  raiseTicket: `${BASE_URL_USER}/support/raise-ticket`,
  fetchTickets: `${BASE_URL_USER}/support/fetch-tickets`,
};

export default ENDPOINTS;