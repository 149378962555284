// src/web3Config.ts
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';
import { mainnet, bsc, bscTestnet } from 'wagmi/chains'; // Ensure bsc is correctly imported from viem

const projectId = '56840373ac24a9cbee4570764722a7ab';

const metadata = {
  name: 'Rainmaker',
  description: 'SmartRainmakers',
  url: 'https://smartrainmakers.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
};

const chains = [bscTestnet, bsc] as const; // Include mainnet (Ethereum) and BSC

export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

export const modal = createWeb3Modal({
  wagmiConfig: config,
  projectId,
});

export const connectWallet = async () => {
  const { connectors } = config;
  
  // Check if MetaMask (or any injected provider) is available
  if (window.ethereum) {
    try {
      const provider = window.ethereum;
      await provider.request({ method: 'eth_requestAccounts' });
      return provider;
    } catch (error) {
      console.error('Failed to connect to MetaMask', error);
      throw error;
    }
  }

  else {
    try{
    await disconnectWallet(); // Disconnect any existing sessions
    modal.open().then(data => {
      console.log("Modal opened", data);
    });

    // Otherwise, fallback to the first connector (assumed to be WalletConnect)
    const connector = connectors[0];
    await connector.connect();
    const provider = (await connector.getProvider()) as any; // Type assertion for provider
    return provider;
  } catch (error) {
    console.error('Failed to connect to wallet', error);
    throw error;
  }
  }
};


export const getConnectedAddress = async (provider: any) => {
  const accounts = await provider.request({ method: 'eth_accounts' });
  console.log(accounts);
  return accounts[0]; // Returning the first account
};

export const disconnectWallet = async () => {
  const { connectors } = config;
  const connector = connectors[0]; // Assuming first connector is used
  const provider = (await connector.getProvider()) as any; // Type assertion for provider
  if (provider.disconnect) {
    await provider.disconnect();
  }
};
