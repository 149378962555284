import React, { useState, useEffect, useContext } from 'react';
import './SupportPage.css'; // Create a CSS file for styling
import axios from 'axios';
import ENDPOINTS from '../endpoints';
import UserAuthContext from '../context/UserAuthContext';

const SupportPage: React.FC = () => {
    const { userData } = useContext(UserAuthContext); // Access user data
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [expandedTickets, setExpandedTickets] = useState<Record<string, boolean>>({});
    const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});

    const toggleExpand = (id: number) => {
        setExpandedRows(prev => ({ ...prev, [id]: !prev[id] }));
    };

    const fetchTickets = async () => {
        setLoading(true);
        try {
            const response = await axios.get(ENDPOINTS.fetchTickets, {
                params: { userId: userData?.id } // Include userId in the request
            });
            setTickets(response.data);
        } catch (error) {
            console.error('Error fetching tickets:', error);
        } finally {
            setLoading(false);
        }
    };

    const submitRequest = async () => {
        if (!subject.trim() || !message.trim()) return alert('Subject and Message cannot be empty');
        setLoading(true);
        try {
            const response = await axios.post(ENDPOINTS.raiseTicket, { subject, description: message, userId: userData?.id }, {
                headers: { 'Content-Type': 'application/json' }
            });
            if (response.status === 201) {
                alert('Request submitted successfully');
                setSubject('');
                setMessage('');
                fetchTickets();
            } else {
                alert('Failed to submit request');
            }
        } catch (error) {
            console.error('Error submitting request:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTickets();
    }, []);

    return (
        <div className="support-page">
            <h1>Support Center</h1>
            <div className="submit-request">
                <h2>Submit a Request</h2>
                <input
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Enter subject..."
                />
                <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Describe your issue or request..."
                />
                <button onClick={submitRequest} disabled={loading}>
                    {loading ? 'Submitting...' : 'Submit'}
                </button>
            </div>
            <div className="tickets-list">
                <h2>Your Tickets</h2>
                {loading ? (
                    <p>Loading tickets...</p>
                ) : tickets.length > 0 ? (
                    <table className="tickets-table">
                        <thead>
                            <tr>
                                <th>Subject</th>
                                <th>Details</th>
                                <th>Status</th>
                                <th>Raised On</th>
                                <th>Ended On</th>
                                <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tickets.map((ticket: any) => (
                                <React.Fragment key={ticket.ticketId}>
                                    <tr>
                                        <td>{ticket.subject}</td>
                                        <td>{ticket.details}</td>
                                        <td>{ticket.status}</td>
                                        <td>{new Date(ticket.createdAt).toLocaleDateString()}</td>
                                        <td>{ticket.closedAt ? new Date(ticket.closedAt).toLocaleDateString() : "N/A"}</td>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                            <button onClick={() => toggleExpand(ticket.id)} style={{ background: 'none', border: 'none', color: '#fff', cursor: 'pointer' }}>
                                                {expandedRows[ticket.id] ? '⌃' : '⌄'}
                                            </button>
                                        </td>
                                    </tr>
                                    {expandedRows[ticket.id] && (
                                        <tr>
                                            <td colSpan={6}>{ticket.description}</td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No tickets found.</p>
                )}
            </div>
        </div>
    );
};

export default SupportPage;
