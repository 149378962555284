import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HeaderDash from '../HeaderDashNew';
import Footer from '../Footer';
import './Notifications.css';
import ENDPOINTS from '../../../endpoints';
import { useUserAuth } from '../../../context/UserAuthContext';

interface Notification {
  _id: string;
  title: string;
  message: string;
  is_read: boolean;
  created_at: string;
}

const Notifications: React.FC = () => {
  const { userData } = useUserAuth();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {

    fetchNotifications();
  }, [userData]);

  const fetchNotifications = async () => {
    // console.log('Fetching notifications for user:', userData);
    // if (!userData) {
    //   console.error('User data not available');
    //   setLoading(false);
    //   return;
    // }

    try {
      const response = await axios.get(
        ENDPOINTS.fetchNotifications,
        {
          params: {
            user_id: userData?.id,
          },
        }
      );
      console.log('Fetched notifications:', response.data.notifications);
      setNotifications(response.data.notifications);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  };
  const markAsRead = async (notificationId: string) => {
    try {
      console.log({
        notification_id: notificationId,
        is_read: true
      });
      const response = await axios.post(ENDPOINTS.updateNotificationStatus, {
        notification_id: notificationId,
        is_read: true
      });
  
      if (response.status === 200) {
        fetchNotifications();  // Refetch notifications
      } else {
        console.error("Failed to mark notification as read:", response.data);
      }
    } catch (error) {
      console.error("Error marking as read:", error);
    }
  };
  if (loading) {
    return <div className="loading">Loading notifications...</div>;
  }

  return (
    <div className="notifications-page">
      <HeaderDash />
      <div className="main-content">
        <h1>Your Notifications</h1>

        <div className="notification-table-wrapper">
        <table className="notification-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Message</th>
              <th>Date</th>
              {/* <th>Status</th> */}
            </tr>
          </thead>
          <tbody>
            {notifications.map((notification) => (
              <tr key={notification._id}>
                <td>{notification.title}</td>
                <td>{notification.message}</td>
                <td>
                  {new Date(notification.created_at).toLocaleDateString()} <br />
                  {new Date(notification.created_at).toLocaleTimeString()}
                </td>
                {/* <td>
                  {notification.is_read ? (
                    <span className="status read">Read</span>
                  ) : (
                    <>
                    <button
                      className="mark-read-btn"
                      onClick={() => markAsRead(notification._id)}
                    >
                      Mark as Read
                    </button>
                  </>                  )}
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Notifications;