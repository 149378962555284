import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './Modal.css';
import Toastify from 'toastify-js';
import { BeatLoader } from 'react-spinners';
import axios from "axios";
import ENDPOINTS from "../../../endpoints";

interface WithdrawModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  userBalance: number;
  amount: number;
  userId: string;
  username: string; // Add username prop
  userAddress: string; // Add userAddress prop
  onConfirmWithdraw: (toAddress: string, selectedToken: string) => void;
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({
  isOpen,
  onRequestClose,
  userId,
  amount,
  userAddress,
  username,
  onConfirmWithdraw,
}) => {
  const [toAddress, setToAddress] = useState('');
  const [selectedToken, setSelectedToken] = useState('BNB (BEP20)');
    const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [selectedRecipient, setSelectedRecipient] = useState<any | null>(null);

  useEffect(() => {
    if (isOpen) {
      setToAddress(userAddress);
      setSearchResults([]);
      setSelectedRecipient(null);
    }
  }, [isOpen]);


  const handleConfirm = () => {
    if (!userAddress) {
      Toastify({
        text: "Please select a valid user or wallet address.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
      return;
    }
    setIsLoading(true);
    callOperationAPI();
    setTimeout(() => {
    onConfirmWithdraw(userAddress, selectedToken);
    Toastify({
      text: "Withdrawal confirmed!",
      duration: 3000,
      close: true,
      gravity: "top",
      position: "center",
      style: {
        background: "linear-gradient(to right, #28a745, #218838)",
      },
    }).showToast();
    onRequestClose();
    setIsLoading(false);
  }, 3000);
  };

  const callOperationAPI = async (): Promise<void> => {
    try {
      console.log("Sending data to operationAPI:", {
        user_id: userId,
        userWalletAddress: selectedRecipient,
        amount: Number(amount),
      });

      // return;
      const response = await axios.post(ENDPOINTS.withdraw, {
        user_id: userId,
        userWalletAddress: selectedRecipient, // Use recipient ID
        amount: Number(amount),
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      console.log("Transfer API Response:", response.data);
  
      if (response.data.status === 'success') { 
        console.log('Transfer API completed successfully:', response.data);
        alert("Transfer successful!");
      } else {
        console.error('Transfer failed:', response.data.error || 'Unknown error');
        // alert(`Transfer failed: ${response.data.error || 'Unknown error'}`);
      }
    } catch (error) {
      console.error("Transfer API call failed:", error);
      // alert("An error occurred while processing the transfer.");
    }
  };

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Withdraw Modal"
      className="withdraw-modal"
    >
      <h2 className="modal-title">Withdraw</h2>
      <div className="modal-content">
        {/* From Section */}
        <div className="transfer-section from-section">
          <label className="section-label">From</label>
          <div className="withdrawal-from-box">
            <p>Smart Contract</p>
          </div>
        </div>
  
        {/* To Section */}
        <div className="transfer-section to-section">
          <label className="section-label">To</label>
          <div className="to-box">
            <div className="to-details">
              <div className="to-address">
                {selectedRecipient
                  ? `${selectedRecipient.slice(0, 5)}.......${selectedRecipient.slice(-5)}`
                  : "Enter Wallet Address"}
              </div>
            </div>
          </div>
        </div>
  
        {/* Wallet Address Input */}
        <div className="wallet-input">
          <label>Your Wallet Address:</label>
          <input
            type="text"
            value={selectedRecipient || ""}
            onChange={(e) => setSelectedRecipient(e.target.value)}
            placeholder="Enter your wallet address"
            className="wallet-address-input"
          />
        </div>
  
        {/* Token Selection */}
        <div className="token-select">
          <label>Select Token: </label>
          <select value={selectedToken} onChange={(e) => setSelectedToken(e.target.value)}>
            <option value="BNB (BEP20)">BNB (BEP20)</option>
          </select>
        </div>
  
        {/* Action Buttons */}
        <div className="transfer-action-buttons">
          <button className="cancel-button" onClick={onRequestClose}>
            Cancel
          </button>
          <button
            className="continue-button"
            onClick={() => handleConfirm()}
            disabled={isLoading || !selectedRecipient}
          >
            {isLoading ? <BeatLoader size={8} color={"#ffffff"} /> : "Confirm Withdrawal"}
          </button>
        </div>
      </div>
    </Modal>
  );
  
};

export default WithdrawModal;