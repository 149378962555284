import React, { useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import AuthContext from "../../context/UserAuthContext";
import Web3Context from "../../context/Web3Context";
import "./HeaderDash.css";
import UserAuthContext from "../../context/UserAuthContext";
import logo from "../../assets/images1/logo/logo.png";

const HeaderDash: React.FC = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { account, isConnected, disconnectWallet } = useContext(Web3Context);
  const { userData } = useContext(UserAuthContext);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!isMenuOpen);
  const closeMenu = () => setMenuOpen(false); // Close menu when a link is clicked

  const handleLogout = () => {
    localStorage.clear();
    disconnectWallet();
    logout();
    navigate("/");
    closeMenu(); // Close menu after logout
  };

  const shortenAddress = (address: string | null, chars = 4): string => {
    if (!address) return "";
    return `${address.slice(0, chars)}...${address.slice(-chars)}`;
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary dashnav">
      <div className="container">
        {/* ✅ Logo & Menu Button Stay in Line */}
        <div className="nav-header">
          <Link to="/dashboard" className="logo-container">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
          <button className="navbar-toggler" onClick={toggleMenu}>
            ☰
          </button>
        </div>

        {/* ✅ Responsive Menu */}
        <div className={`nav-links ${isMenuOpen ? "show" : ""}`}>
          <Link to="/dashboard/profile" className="nav-link" onClick={closeMenu}>
            My Profile
          </Link>
          <Link to="/dashboard/transactions" className="nav-link" onClick={closeMenu}>
            Transaction History
          </Link>
          <Link to="/dashboard/team" className="nav-link" onClick={closeMenu}>
            View Team
          </Link>
          <Link to="/dashboard/notifications" className="nav-link" onClick={closeMenu} style={{ display: "flex", alignItems: "center" }}>
        Notifications
        <span className="notification-badge">*</span>
      </Link>
        </div>

        {/* ✅ Social & Action Buttons */}
        <div className={`nav-actions ${isMenuOpen ? "show" : ""}`}>
          <button className="socialbtn">
            <img src={require("../../assets/images1/icons/facebook.png")} />
          </button>
          <button className="socialbtn">
            <img src={require("../../assets/images1/icons/telegram.png")} />
          </button>
          <button className="socialbtn">
            <img src={require("../../assets/images1/icons/twitter.png")} />
          </button>
          <button className="connectbtn">
            {userData?.registration_method === "wallet"
              ? `Connected: ${shortenAddress(userData?.wallet_address || "")}`
              : `Wallet: ${shortenAddress(userData?.wallet_address || "")}`}
          </button>
          <button className="logoutbtn" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
};

export default HeaderDash;
