import React, { useEffect } from 'react';
import './WithdrawalCompletionModal.css';

interface WithdrawalCompletionModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const WithdrawalCompletionModal: React.FC<WithdrawalCompletionModalProps> = ({ isOpen, onRequestClose }) => {
  useEffect(() => {
    if (isOpen) {
      console.log("WithdrawalCompletionModal is open"); // Debugging log
      const timer = setTimeout(() => {
        onRequestClose(); // Auto-close the modal after 3 seconds
      }, 3000);

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [isOpen, onRequestClose]);

  if (!isOpen) return null;

  return (
    <div className="withdrawal-modal-overlay">
      <div className="withdrawal-modal-content">
        <div className="success-animation">
          <img src={require('../../../assets/images/check1.webp')} alt="Success" />
        </div>
        <h2 className="withdrawal-heading">Withdrawal Completed!</h2>
        <p>Your transaction was successful.</p>

        <div className="confetti-container">
          <div className="confetti"></div>
          <div className="confetti"></div>
          <div className="confetti"></div>
          <div className="confetti"></div>
          <div className="confetti"></div>
          <div className="confetti"></div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalCompletionModal;
