import Web3 from 'web3';
import contractData from './NewTokenABI.json';  // Ensure the ABI is correct and in the same path
import ERC20TokenABI from './TokenABI.json';

const ABI = contractData.abi;

// export const getWeb3 = () => {
//   if (window.ethereum) {
//     const web3 = new Web3(window.ethereum);
//     return web3;
//   } else {
//     console.error("MetaMask is not installed");
//     return null;
//   }
// };

export const getWeb3 = () => {
  const web3 = new Web3();
  
  // Set the provider to BSC Testnet RPC endpoint
  web3.setProvider(new Web3.providers.HttpProvider('https://data-seed-prebsc-1-s1.binance.org:8545'));
  
  return web3;
};

export const getContract = (web3: Web3, contractAddress: string) => {
  return new web3.eth.Contract(ABI, contractAddress);
};

export const getTokenContract = (web3: Web3, contractAddress: string) => {
  return new web3.eth.Contract(ERC20TokenABI, contractAddress);
};

export const getBNBBalance = async (web3: Web3, walletAddress: string) => {
  try {
    const balance = await web3.eth.getBalance(walletAddress); // Returns balance in wei
    console.log("BNB balance in wei:", balance);

const balanceBNB = web3.utils.fromWei(balance, 'ether'); // Convert to BNB
console.log("BNB balance in BNB:", balanceBNB);

return balanceBNB; // Convert wei to BNB (ether)
  } catch (error) {
    console.error("Failed to fetch BNB balance", error);
    return '0';
  }
};

// Function to fetch USDT balance from the wallet address
export const getUSDTBalance = async (web3: Web3, usdtContractAddress: string, walletAddress: string): Promise<string> => {
  try {
    console.log("Fetching USDT balance with contract address:", usdtContractAddress);
    console.log("Using wallet address:", walletAddress);
    const contract = getTokenContract(web3, usdtContractAddress);
    console.log("Contract ABI:", contract);
    console.log(contract.options.address);
    web3.eth.getChainId().then(console.log);
    console.log(contract.methods);
    const address = web3.utils.toChecksumAddress(walletAddress);
    const balance = await contract.methods.balanceOf(address).call();

    // const balance = await contract.methods.balanceOf("0x8A9dae05222B25b8951f6A85ce77537DEeAF0F4d").call();
    console.log("Contract testing:", ERC20TokenABI);
    console.log("USDT balance in wei:", balance);
    if (!balance) {
      console.error("Balance is undefined or invalid");
      return '0';
    }

    // Ensure that balance is defined and is a string, then convert it using fromWei
    return web3.utils.fromWei(balance.toString(), 'mwei');  // USDT uses 6 decimal places (mwei)
  } catch (error) {
    console.error("Failed to fetch USDT balance", error);
    return '0';
  }
};