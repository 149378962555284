// src/context/Web3Context.tsx
import React, { createContext, useState, useEffect } from 'react';
import Web3 from 'web3';
import { connectWallet, disconnectWallet, getConnectedAddress } from '../web3Config';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';


interface Web3ContextType {
  web3: Web3 | null;
  account: string | null;
  isConnected: boolean;
  connectWallet: () => Promise<string | null>;
  disconnectWallet: () => void;
  initWeb3Manually: () => Web3; 
  setWeb3: React.Dispatch<React.SetStateAction<Web3 | null>>; 
  setAccount: React.Dispatch<React.SetStateAction<string | null>>;
}

const Web3Context = createContext<Web3ContextType>({
  web3: null,
  account: null,
  isConnected: false,
  connectWallet: async () => null,
  disconnectWallet: () => {},
  initWeb3Manually: () => new Web3(),
  setWeb3: () => {},
  setAccount: () => {},  
});

export const Web3Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [account, setAccount] = useState<string | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const showToastError = (message: string) => {
    Toastify({
      text: message,
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'center',
      stopOnFocus: true,
      style: {
        background: 'linear-gradient(to right, #FF0000, #CB4335)',
      },
    }).showToast();
  };

  // Function to connect the wallet via WalletConnect or Metamask
  const connectWalletWithModal = async (): Promise<string | null> => {
    try {
      const provider = await connectWallet(); // Trigger modal and connect
      const address = await getConnectedAddress(provider); // Get the connected address
      const web3Instance = new Web3(provider); // Create Web3 instance with provider
      setWeb3(web3Instance);
      setAccount(address);
      setIsConnected(true);
      localStorage.setItem('isConnected', 'true');
      showToastError(address);
      return address;
    } catch (error) {
      console.error('Failed to connect wallet', error);
      return null;
    }
  };

  const disconnectWalletHandler = () => {
    disconnectWallet(); // Disconnect the wallet
    setAccount(null);
    setIsConnected(false);
    setWeb3(null);
    localStorage.removeItem('isConnected');
  };

  const initWeb3Manually = () => {
    const provider = new Web3.providers.HttpProvider('https://data-seed-prebsc-1-s1.binance.org:8545');
    const web3Instance = new Web3(provider);
    setWeb3(web3Instance);
    return web3Instance;
  };

  // Reconnect wallet on page reload if wallet was previously connected
  useEffect(() => {
    const storedIsConnected = JSON.parse(localStorage.getItem('isConnected') || 'false');
    if (storedIsConnected) {
      connectWalletWithModal(); // Automatically reconnect the wallet if previously connected
    }
  }, []);

  return (
    <Web3Context.Provider value={{ web3, account, isConnected, connectWallet: connectWalletWithModal, disconnectWallet: disconnectWalletHandler, initWeb3Manually, setWeb3, setAccount }}>
      {children}
    </Web3Context.Provider>
  );
};

export default Web3Context;