import React, { useEffect, useContext } from 'react';
import Modal from 'react-modal';
import QRCode from 'qrcode.react';
import Web3 from 'web3';
import SmartRainmakersABI from '../../../NewTokenABI.json';
import UserAuthContext from '../../../context/UserAuthContext';
import Toastify from 'toastify-js';

interface DepositModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

Modal.setAppElement('#root');

const DepositModal: React.FC<DepositModalProps> = ({ isOpen, onRequestClose }) => {
  const { userData } = useContext(UserAuthContext);

  const showDepositToast = (message: string) => {
    Toastify({
      text: message,
      duration: 3000,
      gravity: 'top',
      position: 'center',
      backgroundColor: "linear-gradient(to right, #28a745, #218838)",
    }).showToast();
  };

  const copyToClipboard = () => {
    if (userData?.wallet_address) {
      navigator.clipboard.writeText(userData.wallet_address);
      Toastify({
        text: "Wallet address copied to clipboard!",
        duration: 3000,
        gravity: 'top',
        position: 'center',
        backgroundColor: "linear-gradient(to right, #28a745, #218838)",
      }).showToast();
    }
  };

  useEffect(() => {
    const web3 = new Web3(Web3.givenProvider || 'https://data-seed-prebsc-1-s1.binance.org:8545');
    const contractAddress = '0xaB20867aeF75B3AAC217f6D4c2cfe86fE3C61aa6';
    console.log(web3);
    const contract = new web3.eth.Contract(SmartRainmakersABI.abi, contractAddress);
    console.log(contract);
    const listenForDeposits = () => {
      const depositEvent = contract.events.Deposit({
        filter: { user: userData?.wallet_address },
        fromBlock: 'latest',
      });

      depositEvent.on('data', (event: any) => {
        console.log("Deposit event detected:", event);
        showDepositToast("Deposit detected! Your funds have been received.");
      });

      depositEvent.on('error', (error: any) => {
        console.error("Error listening for deposit events:", error);
      });

      return depositEvent;
    };

    let depositEvent: any;
    if (isOpen) {
      depositEvent = listenForDeposits();
    }

    return () => {
      if (depositEvent) {
        depositEvent.off();  // Removes all listeners on this event
      }
    };
  }, [isOpen, userData?.wallet_address]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Deposit Funds in SmartRainmaker Wallets"
      className="deposit-modal"
    >
      <button className="modal-close-btn" onClick={onRequestClose}>X</button>
      <h2 style={{ textAlign: 'center', fontSize: 15 }}>Deposit Funds in SmartRainmaker Wallets</h2>

      <div className="modal-content">
        <div className="network-info">
          <span>Network: BEP20</span>
        </div>

        <div className="qr-code-section">
          <QRCode value={userData?.wallet_address || ''} size={200} />
        </div>

        <div className="address-display">
          <span className="wallet-address">{userData?.wallet_address}</span>
          <img src={require('../../../assets/icons/copy.png')} alt="Copy" className="copy-icon" onClick={copyToClipboard} />
        </div>

        <div className="note">
          <p>This is for BEP20 only. Deposits from other networks will not be accepted.</p>
        </div>

        <button onClick={onRequestClose} className="confirm-button">
          Done
        </button>
      </div>
    </Modal>
  );
};

export default DepositModal;