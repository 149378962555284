import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import HeaderDash from '../../Dashboard/HeaderDashNew';
import Footer from '../Footer';
import './Transactions.css';
import ENDPOINTS from '../../../endpoints';
import UserAuthContext from '../../../context/UserAuthContext';

// Define Transaction and User types
interface Transaction {
  id: number;
  amount: number;
  trx_hash: string;
  type: string;
  description: string;
  created_at: string;
  user_name: string;
  wallet_address: string;
}

interface User {
  user_name: string;
  wallet_address: string;
  created_at: string;
  rainmaker_level: number;
  premium_level: number;
  sponsor_name: string;
}

const Transactions: React.FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const { userData } = useContext(UserAuthContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = userData?.id; // Replace with actual user ID
        const response = await axios.get<{
          user: User;
          transactions: Transaction[];
        }>(ENDPOINTS.transactions, {
          params: { user_id: userId, limit: 10, offset: 0 },
        });
        setTransactions(response.data.transactions);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  if (loading) {
    return <div className="loading">Loading transactions...</div>;
  }

  return (
    <div className="transactions-page">
      <HeaderDash />
      <div className="main-content">
        <h1>Transaction History</h1>
        <div className="transaction-table-wrapper">
        <table className="transaction-table">
          <thead>
            <tr>
              <th>Name/Wallet</th>
              <th>Date</th>
              <th>Transaction Hash</th>
              <th>Amount</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr key={transaction.id}>
                <td>
                  <strong>{transaction.description}</strong>
                  <br />
                  {transaction.wallet_address}
                </td>
                <td>
                  {new Date(transaction.created_at).toLocaleDateString()} <br />
                  {new Date(transaction.created_at).toLocaleTimeString()}
                </td>
                <td>
                    {transaction.trx_hash.slice(0, 6)}...{transaction.trx_hash.slice(-4)}
                </td>
                <td>${transaction.amount}</td>
                <td className={`transaction-type ${transaction.type.toLowerCase()}`}>
                  {transaction.type}
                </td>
                <td>
                <a
                  href={`https://testnet.bscscan.com/tx/${transaction.trx_hash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="details-button">
                    View on Explorer
                  </button>
                  </a>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>

      <Footer />

      {/* Modal for User Details */}
      {showModal && user && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>User Details</h2>
            <p>
              <strong>Username:</strong> {user.user_name}
            </p>
            <p>
              <strong>Address:</strong>{' '}
              <span className="wallet-address" title={user.wallet_address}>
                {user.wallet_address.slice(0, 6)}...{user.wallet_address.slice(-4)}
              </span>
            </p>
            <p>
              <strong>Joined Date:</strong>{' '}
              {new Date(user.created_at).toLocaleDateString()}
            </p>
            <p>
              <strong>Rainmaker Level:</strong> {user.rainmaker_level}
            </p>
            <p>
              <strong>Premium Level:</strong> {user.premium_level}
            </p>
            <p>
              <strong>Sponsor Name:</strong> {user.sponsor_name}
            </p>
            <button className="close-button" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Transactions;