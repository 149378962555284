import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Dashboard from './components/Dashboard/Dashboard';
import RegisterPage from './components/Register/RegisterPage';
import LoginPage from './components/Login/LoginPage';
import Content from './components/content';
import FAQ from './components/faq';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PropagateLoader } from 'react-spinners';
import logo from './assets/images/logo.png';
import Notifications from './components/Dashboard/Notifications/Notifications';
import Profile from './components/Dashboard/Profile/Profile';
import Transactions from './components/Dashboard/Transactions/Transactions';
import Team from './components/Dashboard/Team/Team';

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isMobile) {
      import('./components/content-mobile.css');
    } else {
      import('./components/content.css');
    }
  }, [isMobile]);

  if (loading) {
    return (
      <div className="loader-container">
        <img src={logo} alt="Logo" className="logo" />
        <PropagateLoader color="#c623dc" loading={loading} size={15} />
      </div>
    );
  }

  // Helper variables to determine the current route
  const isDashboardRoute = location.pathname.startsWith('/dashboard');
  const isLoginPage = location.pathname === '/login';
  const isRegisterPage = location.pathname === '/register';

  return (
    <div className="app">
      {/* Render Header and Footer only on non-dashboard, non-login, and non-register pages */}
      {!isDashboardRoute && !isLoginPage && !isRegisterPage && <Header />}
      <Routes>
        <Route path="/" element={<Content />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/faq" element={<FAQ />} />
        {/* Dashboard routes are encapsulated within the Dashboard layout */}
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/dashboard/notifications" element={<Notifications />} />
        <Route path="/dashboard/profile" element={<Profile />} />
        <Route path="/dashboard/transactions" element={<Transactions />} />
        <Route path="/dashboard/team" element={<Team />} />
        <Route path="/dashboard/notifications" element={<Notifications />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {/* Render Footer only on non-dashboard, non-login, and non-register pages */}
      {!isDashboardRoute && !isLoginPage && !isRegisterPage && <Footer />}
    </div>
  );
};

export default App;