import React, { useEffect, useState, useContext } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import HeaderDash from './HeaderDashNew';
import MainContent from './MainContent';
import FooterDash from './Footer';
import Profile from './Profile/Profile';
import Transactions from './Transactions/Transactions';
import Team from './Team/Team';
import Notifications from './Notifications/Notifications';
import UserAuthContext from '../../context/UserAuthContext';
import { PropagateLoader } from 'react-spinners'; // Import PropagateLoader
import './Dashboard.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../assets/images1/logo/logo.png';
import SupportPage from '../../pages/SupportPage';

const Dashboard: React.FC = () => {
  const { token } = useContext(UserAuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // State for loading

  useEffect(() => {
    const storedToken = localStorage.getItem('userData');
    console.log("Stored token:", storedToken);
    setTimeout(() => setLoading(false), 20000); 
    if (!token) {
      console.log("User not authenticated!");
      console.log("Stored token:", storedToken);
      navigate('/login'); // Redirect to login if no token
    } else {
      // Simulate loading delay
      setTimeout(() => setLoading(false), 2000); // Set loading to false after 2 seconds
    }
  }, [navigate]);

  return (
    <div className="dashboard">
      {/* Loader displayed while loading is true */}
      {loading ? (
        <div className="loader-container">
          <img src={logo} alt="Logo" className="logo" />
          <PropagateLoader color="#c623dc" loading={loading} size={15} />
        </div>
      ) : (
        <>
          <HeaderDash />
            <Routes>
              <Route path="/" element={<MainContent />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/team" element={<Team />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/support" element={<SupportPage />} />
            </Routes>
          <FooterDash />
        </>
      )}
    </div>
  );
};

export default Dashboard;
