import React, { useEffect } from 'react';
import './DepositDetectedModal.css';

interface DepositDetectedModalProps {
  isOpen: boolean;
  amount: string;
  onRequestClose: () => void;
}

const DepositDetectedModal: React.FC<DepositDetectedModalProps> = ({ isOpen, amount, onRequestClose }) => {
  useEffect(() => {
    if (isOpen) {
      console.log("DepositDetectedModal is open");
      const timer = setTimeout(() => {
        onRequestClose();
      }, 3000); // Auto-close after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [isOpen, onRequestClose]);

  if (!isOpen) return null;

  return (
    <div className="deposit-modal-overlay">
      <div className="deposit-modal-content">
        <div className="checkmark-animation">
        <img src={require('../../../assets/images/check1.webp')} alt="Success" />
        </div>
        <h2 className="deposit-title">Deposit Detected!</h2>
        <p>Your deposit has been detected of {amount}$.</p>

        <div className="confetti-container">
          <div className="confetti"></div>
          <div className="confetti"></div>
          <div className="confetti"></div>
          <div className="confetti"></div>
        </div>
      </div>
    </div>
  );
};

export default DepositDetectedModal;
