import React, { useContext, useState } from 'react';
import axios, { AxiosError } from 'axios';
import HeaderDash from '../HeaderDashNew';
import Footer from '../Footer';
import UserAuthContext from '../../../context/UserAuthContext';
import './Profile.css';
import ENDPOINTS from '../../../endpoints';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import profile from '../../../assets/icons/user.png';


const Profile: React.FC = () => {
  const { userData } = useContext(UserAuthContext);
  const [showModal, setShowModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      Toastify({
        text: 'New password and confirm password do not match',
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "center",
        stopOnFocus: true,
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
        onClick: function () { }
      }).showToast();
      return;
    }

    try {
      const response = await axios.post(ENDPOINTS.changePassword, {
        email: userData?.email,
        current_password: currentPassword,
        new_password: newPassword,
      });

      if (response.data.success) {
        setShowModal(false);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        Toastify({
          text: "Password changed successfully",
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "center",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { }
        }).showToast();
      } else {
        Toastify({
          text: response.data.message || 'Error changing password',
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "center",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #FF0000, #CB4335)",
          },
          onClick: function () { }
        }).showToast();
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        Toastify({
          text: error.response.data.message || 'Error changing password',
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "center",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #FF0000, #CB4335)",
          },
          onClick: function () { }
        }).showToast();
      } else {
        Toastify({
          text: 'Error changing password',
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "center",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #FF0000, #CB4335)",
          },
          onClick: function () { }
        }).showToast();
      }
    }
  };

  return (
    <div className="profile-page full-width">
      <HeaderDash />
      <div className="profile-content container-fluid theme-background">
        <div className="profile-card">
          <div className="profile-image">
            <img src={profile} alt="Profile" />
          </div>
          <div className="profile-details">
            <div className="profile-field">
              <label>Username:</label>
              <input type="text" value={userData?.user_name || ''} readOnly />
            </div>
            <div className="profile-field">
              <label>Email:</label>
              <input type="text" value={userData?.email || ''} readOnly />
            </div>
            <div className="profile-field">
              <label>Email Verified:</label>
              <span>
                {userData?.email_verified ? (
                  <>
                    Verified <i className="fas fa-check-circle verified"></i>
                  </>
                ) : (
                  <>
                    Not Verified <i className="fas fa-times-circle not-verified"></i>
                  </>
                )}
              </span>
            </div>
            <div className="profile-field">
              <label>Wallet Address:</label>
              <input type="text" value={userData?.wallet_address || ''} readOnly />
            </div>
            <div className="profile-field">
              <label>Referrer Username:</label>
              <input type="text" value={userData?.sponsor_name || ''} readOnly />
            </div>
            {/* <div className="profile-field">
              <label>Referrer Address:</label>
              <input type="text" value={userData?.sponsor_details?.sponsor_wallet_address || ''} readOnly />
            </div> */}
            <div className="profile-field">
              <label>Rainmaker Level:</label>
              <input type="text" value={userData?.rainmaker_level || ''} readOnly />
            </div>
            <div className="profile-field">
              <label>Premium Level:</label>
              <input type="text" value={userData?.premium_level || ''} readOnly />
            </div>
            <div className="profile-field">
              <label>Commission Earnings:</label>
              <input type="text" value={`$${userData?.payout_wallet || '0.00'}`} readOnly />
            </div>
          </div>
          <div role="form">
            <div className="password-section">
              <hr />
              <div className="profile-field">
                <label>Password:</label>
                <input type="password" value="********" readOnly />
                <button className="btn btn-secondary" onClick={() => setShowModal(true)}>Change Password</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {showModal && (
        <div className="change-password-modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <h2 className="modal-heading">Change Password</h2>
            <div className="modal-field">
              <label>Current Password:</label>
              <div className="password-input">
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <i
                  className={`fas ${showCurrentPassword ? "fa-eye-slash" : "fa-eye"}`}
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                ></i>
              </div>
            </div>
            <div className="modal-field">
              <label>New Password:</label>
              <div className="password-input">
                <input
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <i
                  className={`fas ${showNewPassword ? "fa-eye-slash" : "fa-eye"}`}
                  onClick={() => setShowNewPassword(!showNewPassword)}
                ></i>
              </div>
            </div>
            <div className="modal-field">
              <label>Confirm New Password:</label>
              <div className="password-input">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <i
                  className={`fas ${showConfirmPassword ? "fa-eye-slash" : "fa-eye"}`}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                ></i>
              </div>
            </div>
            <button className="btn btn-primary" onClick={handleChangePassword}>Submit</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
