import React from 'react';
import './Footer.css';
import logo from '../assets/images/logo.png'; // Ensure the correct path to the logo

const Footer: React.FC = () => {
  console.log('Footer component is rendered'); // Add this line

  return (
    <footer className="footer">
      <div className="footer-logo">
        <img src={logo} alt="SmartRainmakers Logo" />
      </div>
      <div className="footer-separator"></div>
      <div className="footer-content">
        <div className="footer-column">
          <h3>Legal</h3>
          <ul>
            <li><a href="#terms">Terms & Conditions</a></li>
            <li><a href="#privacy">Privacy Policy</a></li>
            <li><a href="#disclaimer">Disclaimer</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Support</h3>
          <ul>
            <li><a href="#contact">Contact Us</a></li>
            <li><a href="#live-support">Live Support</a></li>
            <li><a href="#request">Submit A Request</a></li>
            <li><a href="#faq">FAQ</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Social Media</h3>
          <ul>
            <li><a href="#telegram">Telegram Channel</a></li>
            <li><a href="#twitter">Twitter</a></li>
            <li><a href="#youtube">YouTube</a></li>
            <li><a href="#tiktok">TikTok</a></li>
            <li><a href="#facebook">Facebook</a></li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
