import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { BeatLoader } from 'react-spinners';
import './ActivateZone.css';
import Toastify from "toastify-js";
import Web3Context from '../../../context/Web3Context';
import UserAuthContext from '../../../context/UserAuthContext';
import { getContract } from '../../../Web3Utils'; // Import Web3Utils functions
import { getBNBPriceInUSD } from '../coingeckoAPI';
import SmartRainmakersABI from '../../../NewTokenABI.json'; // Import ABI
import CongratulationsModal from '../../CongratulationsModal'; // Import CongratulationsModal
import ENDPOINTS from '../../../endpoints';
import Web3 from 'web3';
import { convertUsdToBnb } from '../../../USDToBNBConversion';

interface ActivateZoneProps {
  isOpen: boolean;
  onRequestClose: () => void;
  count: number;
  isRainmaker: boolean;
  zone: string;
  onConfirmActivation: () => void;
}

interface OperationPayload {
  user_id: string;
  rainmakerAmount: number;
  premiumAmount: number;
  referrer_id: string;
  type: 'BNB' | 'USDT';
  zone: string;
}

Modal.setAppElement('#root');

const ActivateZone: React.FC<ActivateZoneProps> = ({ isOpen, onRequestClose, count, isRainmaker, zone, onConfirmActivation }) => {
  const { web3, account, initWeb3Manually } = useContext(Web3Context); // Use Web3Context for web3 and account
  const { userData } = useContext(UserAuthContext); // Use UserAuthContext for user data
  const [bnbPrice, setBnbPrice] = useState<number | null>(null);
  const [bnbEquivalent, setBnbEquivalent] = useState<number | null>(null);  
  const [yourBalance, setYourBalance] = useState<number | null>(null);
  const [yourBNBBalance, setYourBNBBalance] = useState<number | null>(null);
  const [gasFee, setGasFee] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCongratulationsModalOpen, setIsCongratulationsModalOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(120);

  const contractAddress = "0x57e33DEb60407924ec24ED9A7d30E607AA3f9451"; // Your SmartRainmakers contract address

  useEffect(() => {
    if (isOpen) {
      setTimeLeft(500); // Reset timer when modal opens
    }
  }, [isOpen]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (isOpen) {
      onRequestClose();
      Toastify({
        text: "You are timed out.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
    }
  }, [timeLeft, isOpen, onRequestClose]);

  useEffect(() => {
    const fetchBNBPrice = async () => {
      try {
        const price = await getBNBPriceInUSD();
        console.log("Fetched BNB price:", price);
        setBnbPrice(price);
      } catch (error) {
        console.error("Error fetching BNB price:", error);
      }
    };

    fetchBNBPrice();
  }, []);

  useEffect(() => {
    if (bnbPrice) {
      const fetchBalance = async () => {
        try {
          const web3 = new Web3(Web3.givenProvider || 'https://data-seed-prebsc-1-s1.binance.org:8545');
  
          const currentBalance = await web3.eth.getBalance(userData!.wallet_address);
          const balanceInBNB = Number(web3.utils.fromWei(currentBalance.toString(), 'ether'));
          const bnbValue = count / bnbPrice;
          setBnbEquivalent(bnbValue);
          const balanceInUSD = balanceInBNB * bnbPrice;
          setYourBalance(balanceInUSD);
          setYourBNBBalance(balanceInBNB);

          const gasPrice = await web3.eth.getGasPrice(); // Get gas price in Wei
          const gasLimit = 21000; // Standard gas limit for a simple transfer
      
          // Convert gas price from Wei to BNB
          const gasPriceInBNB = Number(web3.utils.fromWei(gasPrice.toString(), 'ether'));
      
          // Calculate total gas fee (gasPrice * gasLimit)
          const gasFeeInBNB = gasPriceInBNB * gasLimit;
      
          // Convert to USD
          const gasFeeInUSD = gasFeeInBNB * bnbPrice;
          setGasFee(gasFeeInUSD);
      
          console.log("Gas Price (Wei):", gasPrice);
          console.log("Gas Price (BNB):", gasPriceInBNB);
          console.log("Total Gas Fee (BNB):", gasFeeInBNB);
          console.log("Total Gas Fee (USD):", gasFeeInUSD);
        } catch (error) {
          console.error("Error fetching balance:", error);
        }
      };
  
      fetchBalance(); // Call the async function inside useEffect
    }
  }, [bnbPrice, count, userData]);
  

  const handleActivation = async () => {
    if (!web3 || !account) {
      Toastify({
        text: "Wallet not connected.",
        duration: 3000,
        gravity: "top",
        position: "center",
        style: { background: "linear-gradient(to right, #FF0000, #CB4335)" },
      }).showToast();
      return;
    }

    if (!bnbPrice || isNaN(bnbPrice)) {
      Toastify({
        text: "BNB price is invalid. Please try again later.",
        duration: 3000,
        gravity: "top",
        position: "center",
        style: { background: "linear-gradient(to right, #FF0000, #CB4335)" },
      }).showToast();
      return;
    }

    showProcessingToast("Processing Activation");

    const bnbValue = count / bnbPrice;
    const bnbValueInWei = web3.utils.toWei(bnbValue.toFixed(8), "ether");
    const payoutAmountInWei = Math.floor(count * 1e18);

    setIsLoading(true);

    try {
      // const gasPrice = await web3.eth.getGasPrice(); // Fetch the current gas price
      // const contract = getContract(web3, contractAddress);
      // console.log("Contract:", contract);
      // console.log("Contract Methods:", contract.methods);
      // const balance = await web3.eth.getBalance(account);
      // console.log("Your BNB Balance:", web3.utils.fromWei(balance, "ether"));
      // console.log("BNB Value (in Wei):", bnbValueInWei);
      // console.log("Payout Value (in Wei):", payoutAmountInWei);
      // console.log("Sender Address:", account);
      // console.log("Referrer Address:", userData?.referrer_address);
      // console.log("BNB Value (in Wei):", bnbValueInWei);
      // console.log("Contract Address:", contract.options.address);
      // // const user = await contract.methods.isUserExists("0x010eAb4b1C43C7DdbAdA2c75FedFC0fbE847eB2f").call();
      // // console.log("User Data:", user);
      // const user = await contract.methods.isUserExists(account).call();
      // console.log("🔍 Calculated Values:");
      // console.log("✅ USD Amount (count):", count);
      // console.log("✅ BNB Price:", bnbPrice);
      // console.log("✅ BNB Value:", bnbValue);
      // console.log("✅ BNB Value in Wei:", bnbValueInWei);
      // console.log("✅ Payout Amount in Wei:", payoutAmountInWei);


      // const gasEstimate = await contract.methods
      //   .deposit(userData?.referrer_address, payoutAmountInWei.toString())
      //   .estimateGas({ from: account, value: bnbValueInWei })
      //   .then((gas) => console.log("✅ Estimated Gas:", gas))
      //   .catch((error) => console.error("🚨 Gas Estimation Failed:", error?.message || error));


      // const adjustedGasLimit = (Number(gasEstimate) + 20000).toString();
      // console.log("User", userData);
      // console.log("Referrer Address:", userData?.referrer_address);
      // console.log("BNB Value (in Wei):", bnbValueInWei);

      // console.log("User Wallet Address:", account);

      // console.log(gasPrice.toString());

      // console.log("Adjusted Gas Limit:", adjustedGasLimit);



      // await contract.methods
      //   .deposit(userData?.referrer_address, payoutAmountInWei.toString())
      //   .send({
      //     from: account,
      //     value: bnbValueInWei,
      //     // gas: adjustedGasLimit,
      //     gasPrice: gasPrice.toString(),
      //   })
      //   .on("transactionHash", (hash: string) => {
      //     console.log("Transaction hash:", hash);

      //     // Show the transaction hash to the user
      //     Toastify({
      //       text: `Transaction submitted! Hash: ${hash}`,
      //       duration: 5000,
      //       gravity: "top",
      //       position: "center",
      //       style: { background: "linear-gradient(to right, #28a745, #218838)" },
      //     }).showToast();

      //     // Open the "Congratulations" modal after 3 seconds
      //     setTimeout(() => {
      //       setIsCongratulationsModalOpen(true);
      //     }, 3000);
      //   })
      //   .on("receipt", (receipt: any) => {
      //     console.log("Transaction confirmed:", receipt);
      //     Toastify({
      //       text: "Zone activated successfully!",
      //       duration: 3000,
      //       gravity: "top",
      //       position: "center",
      //       style: { background: "linear-gradient(to right, #28a745, #218838)" },
      //     }).showToast();
      //   })
      //   .on("error", (error: any) => {
      //     console.error("Transaction failed:", error);
      //     Toastify({
      //       text: "Transaction failed. Please try again.",
      //       duration: 3000,
      //       gravity: "top",
      //       position: "center",
      //       style: { background: "linear-gradient(to right, #FF0000, #CB4335)" },
      //     }).showToast();
      //   });


      if (!userData) {
        console.error("User data is missing!");
        showErrorToast("User data not found. Please try again.");
        setIsLoading(false);
        return;
      }
      // setIsButtonDisabled(false);
      // setIsLoading(false);
      console.log("User Data testing:", userData);
      const payload: OperationPayload = zone === "both" ?
      {
        user_id: userData.id,
        rainmakerAmount: count/2,
        premiumAmount: count/2,
        referrer_id: userData.sponsor_id!,
        type: 'USDT',
        zone: zone
      }
      :
      isRainmaker ? {
        user_id: userData.id,
        rainmakerAmount: count,
        premiumAmount: 0.00,
        referrer_id: userData.sponsor_id!,
        type: 'USDT',
        zone: zone
      }
      :
      {
      user_id: userData.id,
      rainmakerAmount: 0.00,
      premiumAmount: count,
      referrer_id: userData.sponsor_id!,
      type: 'USDT',
      zone: zone
    };

      // Call the operation API
      await callOperationAPI(payload);

      // setTimeout(() => {
      //   setIsCongratulationsModalOpen(true);
      // }, 3000);

    } catch (error) {
      console.error("Error during activation:", error);
      Toastify({
        text: "An error occurred during activation. Please try again.",
        duration: 3000,
        gravity: "top",
        position: "center",
        style: { background: "linear-gradient(to right, #FF0000, #CB4335)" },
      }).showToast();
    } finally {
      setIsLoading(false);
    }
  };

  // Function to call the operation API
  const callOperationAPI = async (payload: OperationPayload): Promise<void> => {
    try {
      console.log("Sending data to operationAPI:", payload);

      const response = await fetch(ENDPOINTS.operation, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload), // Sending payload to the backend
      });

      // Try to parse the response as JSON
      let data: any;
      try {
        data = await response.json();
        console.log("Printing OperationAPI DATA", data);
      } catch (jsonError) {
        // If JSON parsing fails, log an error and return early
        console.error("Failed to parse JSON response:", jsonError);
        throw new Error("Unexpected response format - not JSON");
      }

      console.log("Operation API Response:", data);

      if (data.status === 'success') {
        setIsCongratulationsModalOpen(true);
        handleCloseCongratulationsModal();
        showProcessingToast("Activation Completed Successfully");
        console.log('Operation completed successfully:', data);
      } else {
        showErrorToast(data.message);
        console.error('Operation failed:', data.error || 'Unknown error');
      }
    } catch (error) {
      console.error("Operation API call failed:", error);
    }
  };

  const showProcessingToast = (message: string) => {
    Toastify({
      text: message,
      duration: 5000,
      gravity: 'top',
      position: 'center',
      backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
    }).showToast();
  };

  const showErrorToast = (message: string) => {
    Toastify({
      text: message ?? "Something went wrong!",
      duration: 5000,
      gravity: 'top',
      position: 'center',
      backgroundColor: "linear-gradient(to right, #FF5F6D, #FFC371)",
    }).showToast();
  };

  const handleCloseCongratulationsModal = () => {
    setTimeout(() => {
      setIsCongratulationsModalOpen(false);
      window.location.reload();
      onConfirmActivation();
    }, 5000);
  };

  if (!isOpen) return null;

  return (
    <>
    <Modal
  isOpen={isOpen}
  onRequestClose={onRequestClose}
  contentLabel="Activate Zone Modal"
  className="activation-modal"
>
  <h2>Activate Zone</h2>
  <div className="modal-content">
    <table>
      <tbody>
        <tr>
          <td>Activation Fee</td>
          <td>${count} {bnbPrice && `(${bnbEquivalent?.toFixed(4)} tBNB)`}</td>
        </tr>
        {yourBalance && (
          <tr>
            <td>Your Balance</td>
            <td>${yourBalance.toFixed(2)} ({yourBNBBalance?.toFixed(4)} tBNB)</td>
          </tr>
        )}
        {gasFee && (
          <tr>
            <td>Gas Fee</td>
            <td>{gasFee?.toFixed(2)} $ appx.</td>
          </tr>
        )}
      </tbody>
    </table>

    <button
      onClick={handleActivation}
      className="confirm-button"
      disabled={isLoading}
    >
      {isLoading ? <BeatLoader size={8} color={"#ffffff"} /> : 'Confirm Activation'}
    </button>
  </div>
</Modal>


      <CongratulationsModal
        isOpen={isCongratulationsModalOpen}
        onRequestClose={handleCloseCongratulationsModal}
      />
    </>
  );
};

export default ActivateZone;