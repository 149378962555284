import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import './MainContent.css';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import Web3Context from '../../context/Web3Context';
import { getWeb3, getContract, getTokenContract, getBNBBalance, getUSDTBalance } from '../../Web3Utils';
import Web3 from 'web3';
import CongratulationsModal from '../CongratulationsModal';
import DepositModal from './TransactionModals/DepositModal';
import WithdrawModal from './TransactionModals/WithdrawModal';
import TransferModal from './TransactionModals/TransferModal';
import Zone from './Zone'; // Import the Zone component
import UserAuthContext from '../../context/UserAuthContext'; // Import UserAuthContext
import SmartRainmakersABI from '../../NewTokenABI.json';
import ActivateZone from './ActivateZone/ActivateZone';
import ENDPOINTS from '../../endpoints';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/responsive.css';
// import './css/owl.carousel.min.css';
import './css/owl.theme.default.min.css';
import './css/sweetalert2.min.css';
import ActivationModal from '../Dashboard/ActivateZone/ActivateZone'; // Correct path to ActivationModal
import { convertUsdToBnb, fetchBNBPrice } from '../../USDToBNBConversion';
import WithdrawalCompletionModal from './Modals/WithdrawalCompletionModal';
import DepositDetectedModal from './Modals/DepositDetectedModal';
import { FaChevronDown, FaCoins } from 'react-icons/fa';



interface Referral {
  user_name: string;
  wallet_address: string;
  created_at: string;
}


const MainContent: React.FC = () => {
  const { web3, account: userAddress, isConnected, connectWallet, initWeb3Manually, setWeb3, setAccount } = useContext(Web3Context);
  const { userData, setUserData, login, fetchUserDetailsFromContract } = useContext(UserAuthContext); // Use setUserData and login from UserAuthContext
  const [token, setToken] = useState<string | null>(null);  // State to hold the token
  const [contract, setContract] = useState<any>(null);
  const [usdtContract, setUsdtContract] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWithdrawalModalOpen, setIsWithdrawalModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [previousBalance, setPreviousBalance] = useState<bigint>(BigInt(0));
  const [walletBalance, setWalletBalance] = useState<string>('0');
  const [error, setError] = useState<string | null>(null);
  const [zonesVisible, setZonesVisible] = useState(false); // State for toggling visibility
  const [bnbBalance, setBNBBalance] = useState<string>('0');  // State to hold BNB balance
  const [usdtBalance, setUSDTBalance] = useState<string>('0');
  const [selectedBalance, setSelectedBalance] = useState('BNB'); // Default to BNB
  const [showDropdown, setShowDropdown] = useState(false);
  const [activationAmount, setActivationAmount] = useState<number | null>(null);
  const [activationIsRainmaker, setActivationIsRainmaker] = useState<boolean | false>(false);


  // States for modals
  const [isDepositModalOpen, setDepositModalOpen] = useState(false);
  const [isDepositDetectedModalOpen, setDepositDetectedModalOpen] = useState(false);
  const [isWithdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [isTransferModalOpen, setTransferModalOpen] = useState(false);
  const [isCongratulationsModalOpen, setCongratulationsModalOpen] = useState(false);

  // States to hold amounts for modals
  const [depositAmount, setDepositAmount] = useState<number | null>(null);
  const [usersCount, setUsersCount] = useState<number | 0>(0);
  const [newUsersCount, setNewUsersCount] = useState<number | 0>(0);
  const [transactionCount, setTransactionCount] = useState<number | 0>(0);
  const [totalTransactionsIn24Hrs, setTotalTransactionsIn24Hrs] = useState<number | 0>(0);
  const [depositDetectedAmount, setDepositDetectedAmount] = useState<string | null>(null);
  const [withdrawAmount, setWithdrawAmount] = useState<number | null>(null);
  const [transferAmount, setTransferAmount] = useState<number | null>(null);
  const [isActivationModalOpen, setActivationModalOpen] = useState(false);
  const [activationZone, setActivationZone] = useState<string | null>(null);


  const MAIN_CONTRACT_ADDRESS = "0x57e33DEb60407924ec24ED9A7d30E607AA3f9451";
  const USDT_CONTRACT_ADDRESS = "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd";

  const [isCollapsedPremium, setIsCollapsedPremium] = useState(false);
  const [isCollapsedRainmaker, setIsCollapsedRainmaker] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [rainmakerData, setRainamkerData] = useState<{
    level: string;
    entryFee: string;
    reentryCount?: string;
    childs?: any[];
  }[]>([]);
  const [premiumData, setPremiumData] = useState<{
    level: string;
    entryFee: string;
    reentryCount?: string;
    childs?: any[];
  }[]>([]);

  const toggleCollapsePremium = () => setIsCollapsedPremium(!isCollapsedPremium);
  const toggleCollapseRainmaker = () => setIsCollapsedRainmaker(!isCollapsedRainmaker);


  const generateReferralLink = (username: string) => {
    return `https://smart-rainmakers-march-rho.vercel.app/register?sponsor=${username}`;
  };

  const referralLink = generateReferralLink(userData?.user_name || '');

  const CopyEl2 = () => {
    navigator.clipboard.writeText(referralLink);
    Toastify({
      text: "Referral Link Copied",
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: "top",
      position: "center",
      stopOnFocus: true,
      style: {
        background: "linear-gradient(to right, #00b09b, #96c93d)",
      },
      onClick: function () { }
    }).showToast();
  };
  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectBalance = (value: string) => {
    setSelectedBalance(value);
    setIsOpen(false); // Close dropdown after selection
  };


  useEffect(() => {
    const fetchUserDataOnRefresh = async () => {
      // Only call the function if a token is available (ensuring the user is logged in)
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.post(ENDPOINTS.getUserDetails, {
            token: token
          });

          if (response.data.message === 'success') {
            const { user_details, direct_referrals, rainmakerLevels, premiumLevels, usersCount, newUsersCount, totalTansactions, totalTransactionsIn24Hrs } = response.data;
            console.log(rainmakerData);
            console.log(premiumData);

            setRainamkerData(rainmakerLevels);
            setPremiumData(premiumLevels);
            console.log("userData.is_paid:", userData?.is_paid);
            console.log("userData.is_paid:", user_details?.is_paid);
            setUserData(user_details);
            setUsersCount(usersCount);
            setNewUsersCount(newUsersCount);
            setTransactionCount(totalTansactions);
            setTotalTransactionsIn24Hrs(totalTransactionsIn24Hrs);
            login({ user_details, direct_referrals }); // Use the login function from UserAuthContext to update user data
            console.log("User details fetched successfully on page refresh");
            console.log(response.data);
          } else {
            console.error("Failed to fetch user details:", response.data.error);
          }
        } catch (error) {
          console.error("Error fetching user details on page refresh:", error);
        }
      } else {
        console.error("No token found. User is not logged in.");
      }
    };

    // Call fetchUserDataOnRefresh on initial render (page refresh)
    fetchUserDataOnRefresh();
  }, []); // Empty dependency array ensures this only runs once, on component mount

  const handleOpenDepositModal = () => {
    setDepositModalOpen(true);
  };

  const handleCloseDepositModal = () => {
    setDepositModalOpen(false);
  };


  const handleShowCongratulationsModal = () => {
    setDepositModalOpen(false); // Close Deposit Modal
    setCongratulationsModalOpen(true); // Open Congratulations Modal

    // Fetch updated user details after deposit
    fetchUserDetails();
  };

  const handleCloseCongratulationsModal = () => {
    setCongratulationsModalOpen(false);
  };

  // Function to fetch updated user details after deposit
  const fetchUserDetails = async () => {
    console.log('Fetch User Details function called');
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(ENDPOINTS.getUserDetails, {
        token: token ?? ''
      });

      if (response.data.message === 'success') {
        const { user_details, direct_referrals, rainmakerData, premiumData, rainmakerLevels, premiumLevels } = response.data;
        console.log(rainmakerData);
        console.log(premiumData);

        setRainamkerData(rainmakerLevels);
        setPremiumData(premiumLevels);
        console.log("userData.is_paid:", userData?.is_paid);
        console.log("userData.is_paid:", user_details?.is_paid);
        setUserData(user_details);
        login({ user_details, direct_referrals }); // Use the login function from UserAuthContext to update user data
        console.log("User details fetched successfully on page refresh");
        console.log(response.data);
      } else {
        console.error("Failed to fetch user details:", response.data.error);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {

    console.log("Consoling UserData", userData);
    console.log("Consoling direct referrals", userData?.direct_referrals);
    if (userData) {
      const rainmakerLevelString = userData.rainmaker_level ? userData.rainmaker_level.split(".")[0] : '0';
      const premiumLevelString = userData.premium_level ? userData.premium_level.split(".")[0] : '0';

      const rainmakerLevel = parseFloat(userData.rainmaker_level);
      const premiumLevel = parseFloat(userData.premium_level);
      console.log("Rainmaker Level:", rainmakerLevelString);
      console.log("Premium Level:", premiumLevelString);

      const depositAmountFromDB = rainmakerLevel * 10 + premiumLevel * 20;
      setDepositAmount(depositAmountFromDB);
    }
  }, [userData]);

  const initializeContracts = async (web3Instance: Web3) => {
    try {
      const contractInstance = getContract(web3Instance, MAIN_CONTRACT_ADDRESS);
      const usdtContractInstance = getTokenContract(web3Instance, USDT_CONTRACT_ADDRESS);

      setContract(contractInstance);
      setUsdtContract(usdtContractInstance);

      console.log("Contracts initialized", { contractInstance, usdtContractInstance });
    } catch (error) {
      console.error("Contract initialization error:", error);
      Toastify({
        text: "Failed to initialize contracts. Please try again.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
    }
  };

  const handleActivationModal = async (currentZone: any, otherZone: any, isRainmaker: boolean, zone: string) => {
    console.log("Level:", currentZone);
    console.log("Zones Data:", otherZone);
    if (isRainmaker) {
      console.log(((userData!.rainmaker_level ? Number(userData!.rainmaker_level.split(".")[0]) : 0) + 1));
      if (Number(currentZone.level) > ((userData!.rainmaker_level ? Number(userData!.rainmaker_level.split(".")[0]) : 0) + 1)) {
        Toastify({
          text: `Sorry, Please activate zone ${((userData!.rainmaker_level ? Number(userData!.rainmaker_level.split(".")[0]) : 0) + 1)}`,
          duration: 5000,
          close: true,
          gravity: "top",
          position: "center",
          style: {
            background: "linear-gradient(to right, #FF0000, #CB4335)",
          },
        }).showToast();
        return
      }
    } else {
      console.log(((userData!.premium_level ? Number(userData!.premium_level.split(".")[0]) + 1 : 0) + 1));
      if (Number(currentZone.level) > ((userData!.premium_level ? Number(userData!.premium_level.split(".")[0]) : 0) + 1)) {
        Toastify({
          text: `Sorry, Please activate zone ${((userData!.premium_level ? Number(userData!.premium_level.split(".")[0]) : 0) + 1)}`,
          duration: 5000,
          close: true,
          gravity: "top",
          position: "center",
          style: {
            background: "linear-gradient(to right, #FF0000, #CB4335)",
          },
        }).showToast();
        return
      }
    }
    const amount = zone === "both" ? (parseFloat(currentZone.entryFee) + parseFloat(otherZone.entryFee)) : parseFloat(currentZone.entryFee);

    console.log("Amount:", amount);

    console.log('Activating ', zone);
    const web3 = new Web3(Web3.givenProvider || 'https://data-seed-prebsc-1-s1.binance.org:8545');
    const currentBalance = await web3.eth.getBalance(userData!.wallet_address);
    const balanceInBNB = Number(web3.utils.fromWei(currentBalance.toString(), 'ether'));
    console.log(currentBalance);
    console.log(balanceInBNB);
    const amountInBnb = await convertUsdToBnb(amount);
    console.log(amountInBnb);

    setActivationAmount(Number(amount.toFixed(2)));
    setActivationIsRainmaker(isRainmaker);
    setActivationModalOpen(true);
    setActivationZone(zone);
    // if (balanceInBNB < (amountInBnb ?? 0)) {
    //   Toastify({
    //     text: "Oops! You don't have enough BNB to activate the zone. Please deposit some funds",
    //     duration: 5000,
    //     close: true,
    //     gravity: "top",
    //     position: "center",
    //     style: {
    //       background: "linear-gradient(to right, #FF0000, #CB4335)",
    //     },
    //   }).showToast();
    // } else {
    //   setActivationAmount(Number(amount.toFixed(2)));
    //   setActivationModalOpen(true); // Open Activation Modal
    // }
  };

  // ================================Initialize Web3 manually for email/password login
  useEffect(() => {
    const initWeb3ForEmailPassword = async () => {
      if (!isConnected && userData?.registration_method === 'email_password' && userData?.wallet_address) {
        try {
          const web3Instance = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545/'); // BSC public provider
          setWeb3(web3Instance); // Set Web3 instance in context
          setAccount(userData.wallet_address); // Set the wallet address in the Web3 context
          console.log("Web3 initialized for email_password users with wallet address:", userData.wallet_address);

          await initializeContracts(web3Instance); // Initialize contracts after Web3 is set
          fetchBalances(web3Instance, userData.wallet_address); // Fetch balance after Web3 is initialized
        } catch (error) {
          console.error("Error initializing Web3 for email_password login:", error);
        }
      }
    };

    if (!isConnected && userData?.wallet_address && userData?.registration_method === 'email_password') {
      initWeb3ForEmailPassword();
    }
  }, [userData, isConnected, setWeb3, setAccount]);

  // ===============================Initialize contracts once Web3 is available and connected (for WalletConnect)
  useEffect(() => {
    if (web3 && isConnected && userAddress) {
      initializeContracts(web3);
    }
  }, [web3, isConnected, userAddress]);

  // ==============================Fetch the token once during the component mount
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      console.log("Token stored: ", storedToken);
      setToken(storedToken);  // Update token state
    } else {
      console.error("No token found in localStorage");
      setLoading(false);  // Stop loading if no token is found
    }
  }, []);

  // ==============================Fetch user data once the token is available
  // Ensure that user data is loaded correctly
  useEffect(() => {
    if (userData) {
      console.log('User data found:', userData); // Debugging: Ensure userData is detected
      setLoading(false); // Stop loading once userData is available
    } else {
      console.error('No user data found. Please log in.');
      setLoading(false);
    }
  }, [userData])


  // ==================================Function to fetch user details on contract and wallet balances

  useEffect(() => {
    const fetchData = async () => {
      if (userData?.wallet_address) {
        console.log('Fetching user details from the contract...');
        await fetchUserDetailsFromContract(contract, userData.wallet_address);
        setLoading(false);
      }
    };

    fetchData();
  }, [userData, contract, fetchUserDetailsFromContract]);

  useEffect(() => {
    const web3 = new Web3(Web3.givenProvider || 'https://data-seed-prebsc-1-s1.binance.org:8545');
    const contractAddress = '0xaB20867aeF75B3AAC217f6D4c2cfe86fE3C61aa6';
    const contract = new web3.eth.Contract(SmartRainmakersABI.abi, contractAddress);

    const checkBalance = async () => {
      if (userData?.wallet_address) {
        try {
          const currentBalance = BigInt(await web3.eth.getBalance(userData.wallet_address));
          const bnbPrice = await fetchBNBPrice();
          const currentBalanceInBNB = Number(web3.utils.fromWei(currentBalance.toString(), 'ether'));

          const currentBalanceInUSD = currentBalanceInBNB * (bnbPrice ?? 0);
          setWalletBalance(currentBalanceInUSD.toFixed(2).toString());
          // Detect increase in balance (deposit)
          if (previousBalance !== BigInt(0) && currentBalance > previousBalance) {
            setDepositDetectedModalOpen(true);
            // showDepositToast("Deposit detected! Your funds have been received.");
            const balanceInBNB = Number(web3.utils.fromWei((currentBalance - previousBalance).toString(), 'ether'));
            const balanceInUSD = balanceInBNB * (bnbPrice ?? 0);
            setDepositDetectedAmount(balanceInUSD.toFixed(2).toString());
          }

          setPreviousBalance(currentBalance); // Update balance for future comparisons
        } catch (error) {
          console.error("Error checking balance:", error);
        }
      }
    };

    // Polling every 15 seconds
    checkBalance();
    const intervalId = setInterval(checkBalance, 15000);

    // Fetch user details from the contract when the component mounts
    const fetchData = async () => {
      if (userData?.wallet_address) {
        console.log('Fetching user details from the contract...');
        await fetchUserDetailsFromContract(contract, userData.wallet_address);
        setLoading(false);
      }
    };

    fetchData();

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [userData, previousBalance]);

  const showDepositToast = (message: string) => {
    Toastify({
      text: message,
      duration: 3000,
      gravity: 'top',
      position: 'center',
      backgroundColor: 'linear-gradient(to right, #28a745, #218838)',
    }).showToast();
  };

  useEffect(() => {
    if (userData?.registration_method === 'email_password' && web3 && userData?.wallet_address) {
      fetchBalances(web3, userData.wallet_address); // Use the wallet address from userData for email_password users
    } else if (isConnected && web3 && userAddress) {
      fetchBalances(web3, userAddress); // Use the connected wallet for WalletConnect users
    }
  }, [web3, userAddress, isConnected, userData]);

  const fetchBalances = async (web3Instance: Web3, walletAddress: string) => {
    console.log(web3Instance);
    if (web3Instance && walletAddress) {
      try {
        console.log("Fetching balances for wallet address:", walletAddress);

        // Fetch BNB balance
        const bnb = await getBNBBalance(web3Instance, walletAddress);
        const bnbFormatted = parseFloat(bnb).toFixed(3); // Convert to float and shorten to 2 decimals
        setBNBBalance(bnbFormatted);
        console.log("BNB wallet balance:", bnbFormatted);

        // Fetch USDT balance
        const usdt = await getUSDTBalance(web3Instance, USDT_CONTRACT_ADDRESS, walletAddress);
        const usdtFormatted = parseFloat(usdt).toFixed(2); // Convert to float and shorten to 2 decimals
        setUSDTBalance(usdtFormatted);
        console.log("USDT wallet balance:", usdtFormatted);
      } catch (error) {
        console.error("Error fetching balances:", error);
      }
    } else {
      console.error("Web3 or wallet address is missing.");
    }
  };


  const handleDeposit = async () => {

    const depositAmountInput = document.getElementById('depositAmount') as HTMLInputElement;
    const depositAmount = parseFloat(depositAmountInput?.value);


    // Check if depositAmountInput is equal to 5.00
    if (depositAmount < 6.00 || depositAmount < 5000.00) {
      console.error("Deposit amount must be more than 6");
      Toastify({
        text: "Please enter a deposit amount of between $6 and $5000.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
      return; // Exit the function if the amount is not 5.00
    }

    if (!depositAmountInput || isNaN(depositAmount) || depositAmount <= 0) {
      console.error("Invalid deposit amount");
      Toastify({
        text: "Please enter a valid deposit amount greater than 0.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
      return;
    }

    if (!web3) {
      console.error("Web3 is not initialized");
      Toastify({
        text: "Web3 is not initialized. Please make sure you are connected to a wallet.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
      return;
    }

    if (!userAddress) {
      console.error("User address is not set");
      Toastify({
        text: "User address is not set. Please make sure your wallet is connected.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
      return;
    }

    try {
      if (!contract || !usdtContract) {
        console.log("Contracts not initialized, initializing now...");
        await initializeContracts(web3);
        if (!contract || !usdtContract) {
          console.error("Failed to initialize contracts.");
          Toastify({
            text: "Failed to initialize contracts. Please try again.",
            duration: 3000,
            close: true,
            gravity: "top",
            position: "center",
            style: {
              background: "linear-gradient(to right, #FF0000, #CB4335)",
            },
          }).showToast();
          return;
        }
      }

      // await approveUSDTTransfer(depositAmount);
      setDepositAmount(depositAmount);
      setDepositModalOpen(true);
    } catch (error) {
      console.error("Deposit failed", error);
      Toastify({
        text: "Deposit failed. Please try again.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
    }
  };

  const handleWithdraw = () => {
    const withdrawAmountInput = document.getElementById('withdrawAmount') as HTMLInputElement;
    const amount = parseFloat(withdrawAmountInput?.value);

    if (!amount || isNaN(amount) || amount < 1) {
      Toastify({
        text: "Amount must be at least $10.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
      return;
    }

    if (amount > Number(userData?.payout_wallet)) {
      Toastify({
        text: "Oops!, you have less payout balance.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
      return;
    }
    setWithdrawAmount(amount);
    setWithdrawModalOpen(true); // Open Withdraw Modal
  };

  const handleTransfer = async () => {
    const transferAmountInput = document.getElementById('transferAmount') as HTMLInputElement;
    const amount = parseFloat(transferAmountInput?.value);

    if (!amount || isNaN(amount) || amount <= 0) {
      Toastify({
        text: "Please enter a valid transfer amount.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
      return;
    }

    console.log("Amount:", amount);
    const web3 = new Web3(Web3.givenProvider || 'https://data-seed-prebsc-1-s1.binance.org:8545');
    const currentBalance = await web3.eth.getBalance(userData!.wallet_address);
    const balanceInBNB = Number(web3.utils.fromWei(currentBalance.toString(), 'ether'));
    console.log(currentBalance);
    console.log(balanceInBNB);
    const amountInBnb = await convertUsdToBnb(amount);
    console.log(amountInBnb);
    console.log(userData?.payout_wallet);
    if ((balanceInBNB < (amountInBnb ?? 0) && Number(userData?.payout_wallet) < amount)) {
      Toastify({
        text: "Oops! You don't have enough balance to transfer.",
        duration: 5000,
        close: true,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
    } else {
      setTransferAmount(amount);
      setTransferModalOpen(true); // Open Transfer Modal
    }

  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log("Address copied to clipboard!");
        Toastify({
          text: "Wallet address copied to clipboard!",
          close: true,
          duration: 3000,
          gravity: 'top',
          position: 'center',
          backgroundColor: 'linear-gradient(to right, #28a745, #218838)',
        }).showToast();
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };


  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Render the main content of the dashboard.
   *
   * This function renders the main content of the dashboard based on the state of the
   * application. If the user data is still loading, it displays a loading message. If
   * there is an error, it displays an error message. If the user data is available, it
   * renders the dashboard content.
   */
  /******  1adaacf5-c0c2-46d0-a27d-8291cba10141  *******/
  const renderContent = () => {
    if (loading) {
      return <div className="loader">Loading...</div>;
    }

    if (error) {
      return <div className="error">{error}</div>;
    }

    if (userData) {
      const { user_name, sponsor_name, direct_team, wallet_address } = userData;

      console.log("User Name:", user_name);
      console.log("Sponsor Name:", sponsor_name);
      console.log("Direct Team Count:", direct_team);
      console.log("Wallet Address:", wallet_address);

      return (
        <>
          <div className='background-dashboard'>
            <div className="cont">
              <div className="userdetails">

                <div className="row">
                  <div className="col-md-3">
                    <div className="userdiv text-center">
                      <div className="userdiv-inner">
                        <img src={require('../../assets/images1/icons/user-trust.png')} alt="User Icon" />
                      </div>
                      <h2>User ID: <span>{userData.id ? `${userData.id.slice(0, 6)}...${userData.id.slice(-4)}` : 'N/A'}</span></h2>

                      <button className="levels">Premium Level: {userData.premium_level ? userData.premium_level.split('.')[0] : '0'}</button>
                      <button className="levels">Rainmaker Level: {userData.rainmaker_level ? userData.rainmaker_level.split('.')[0] : '0'}</button>
                      <button className="levels" onClick={() => copyToClipboard(userData.wallet_address)}>
                        <span>
                          My Address: {userData.wallet_address ? `${userData.wallet_address.slice(0, 4)}...${userData.wallet_address.slice(-3)}` : 'N/A'}
                        </span>
                        {userData.wallet_address && <span className="copy-text">Copy</span>}
                      </button>
                    </div>
                  </div>
                  <div className="col-md-9">

                    <div className="referraldiv">
                      <h2>Referral Link:</h2>
                      {userData.is_paid ? (
                        <div className="row">
                          <div className="col-md-10">
                            <input
                              type="text"
                              className="referraltext"
                              value={referralLink}
                              readOnly
                            />
                          </div>
                          <div className="col-md-2">
                            <button className="copybtn" onClick={CopyEl2}>Copy</button>
                          </div>
                        </div>
                      ) : (
                        <p className="activate-message">Please activate your account to get a referral link.</p>
                      )}
                    </div>

                    <div className="joineddiv">

                      <div className="row">
                        <div className="col-md-3 text-center">
                          <h2>Joined in 24hrs:</h2>
                          <h3>{newUsersCount}</h3>
                        </div>
                        <div className="col-md-6 text-center">
                          <h2>Donations Received By Participants in 24Hrs:</h2>
                          <h3>{totalTransactionsIn24Hrs} USDT</h3>
                        </div>
                        <div className="col-md-3 text-center">
                          <h2>Total Transactions:</h2>
                          <h3>{transactionCount}</h3>
                        </div>
                      </div>

                    </div>


                    <div className="statsdiv">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="statscard text-center">
                            <img src={require('../../assets/images1/icons/users-medical.png')} alt="Direct Referrals" />
                            <h2>Direct Referrals:</h2>
                            <h2>{userData.direct_team}</h2>
                          </div>
                        </div>

                        {/* <span>${selectedBalance === "BNB" ? walletBalance || "0.00" : usdtBalance || "0.00"}</span> */}

                        <div className="col-md-3">
                          <div className="statscard text-center">
                            <img src={require('../../assets/images1/icons/wallet-income.png')} alt="Wallet Balance" />
                            <h2>Wallet Balance:</h2>

                            <div className="balance-container">
                              {/* Styled Select Dropdown */}
                              <div className="custom-dropdown">
                                <span className="balance-value">$ {selectedBalance === "BNB" ? walletBalance || '0.00' : usdtBalance || '0.00'}</span>
                                <select
                                  className="balance-dropdown"
                                  value={selectedBalance}
                                  onChange={(e) => setSelectedBalance(e.target.value)}
                                >
                                  <option value="BNB">BNB</option>
                                  <option value="USDT">USDT</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="statscard text-center">
                            <img src={require('../../assets/images1/icons/hand-holding-usd.png')} alt="Payout Wallet" />
                            <h2>Payout Wallet:</h2>
                            <h2>$ {userData.payout_wallet || '0.00'}</h2>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="statscard text-center">
                            <img src={require('../../assets/images1/icons/population-globe.png')} alt="All Participants" />
                            <h2>All Participants:</h2>
                            <h2>{usersCount}</h2>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>


              </div>
            </div>


            <div className="cont">
              <div className="zones">

                <div className="row">
                  <div className="col-md-3">
                    <div className="inputcard text-center">
                      <img src={require('../../assets/images1/icons/chart.png')} alt='Deposit' className="mainicon" />
                      <input type="number" id="depositAmount" min="10" className="deposit-input" />
                      <button className="subbtn" onClick={handleDeposit}>Deposit</button>
                    </div>
                    <div className="inputcard text-center">
                      <img src={require('../../assets/images1/icons/change.png')} alt='Transfer' className="mainicon" />
                      <input type="number" id="transferAmount" min="1" className="transfer-input" />
                      <button className="subbtn" onClick={handleTransfer}>Transfer</button>
                    </div>
                    <div className="inputcard text-center">
                      <img src={require('../../assets/images1/icons/wallet.png')} alt='Withdraw' className="mainicon" />
                      <input type="number" id="withdrawAmount" min="100" className="withdraw-input" />
                      <button className="subbtn" onClick={handleWithdraw}>Withdraw</button>
                    </div>
                  </div>
                  <div className="col-md-9">

                    <div className="zonesdiv">
                      <div className="accordion" id="accordionExample">

                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button"
                              type="button"
                              onClick={() => setIsCollapsedPremium(!isCollapsedPremium)}
                            >
                              {isCollapsedPremium ? "HIDE ZONES" : "PREMIUM  ZONES"}
                            </button>
                          </h2>
                          {isCollapsedPremium && (
                            <div className="accordion-collapse">
                              <div className="accordion-body">
                                <div className="row">
                                  {premiumData.map((zone, index) => {
                                    const isActivated = (userData.premium_level ? Number(userData.premium_level.split(".")[0]) : 0) >= (index + 1);

                                    console.log(`Zone ${zone.level} Childs:`, zone.childs);

                                    const filledDots = Array.isArray(zone.childs) ? zone.childs : [];

                                    return (
                                      <div className="col-md-4" key={zone.level}>
                                        <div className="zonecard">
                                          <h2 className="zonetext">Zone {zone.level}</h2>
                                          <h3 className="dollar">
                                            <img src={require('../../assets/images1/icons/tether.png')} alt="dollar" />
                                            ${index === 0 ? parseFloat(zone.entryFee) + parseFloat(rainmakerData[index].entryFee) : zone.entryFee}
                                          </h3>
                                          <h2 className="people">
                                            <img src={require('../../assets/images1/icons/users.png')} alt="people" /> {filledDots.length + (filledDots.length > 0 ? filledDots[0].children.length : 0) + (filledDots.length > 1 ? filledDots[1].children.length : 0)}
                                          </h2>
                                          <h3 className="refresh">
                                            <img src={require('../../assets/images1/icons/refresh.png')} alt="refresh" /> {zone.reentryCount}
                                          </h3>

                                          {/* Always Render Dots (Even for Empty Slots) */}
                                          <div className={`dot middle-dot ${isActivated ? "filled" : ""}`}>
                                          </div>

                                          <div className={`dot left-middle-dot ${filledDots[0] ? "filled" : ""}`}>
                                            <div className="tooltip">User Name: {filledDots[0]?.username || "N/A"}</div>
                                          </div>

                                          <div className={`dot left-left-dot ${(filledDots[0] && filledDots[0].children?.[0]) ? "filled" : ""}`}>
                                            <div className="tooltip">User Name: {filledDots[0]?.children?.[0]?.username || "N/A"}</div>
                                          </div>

                                          <div className={`dot left-right-dot ${(filledDots[0] && filledDots[0].children?.[1]) ? "filled" : ""}`}>
                                            <div className="tooltip">User Name: {filledDots[0]?.children?.[1]?.username || "N/A"}</div>
                                          </div>

                                          <div className={`dot right-middle-dot ${filledDots[1] ? "filled" : ""}`}>
                                            <div className="tooltip">User Name: {filledDots[1]?.username || "N/A"}</div>
                                          </div>

                                          <div className={`dot right-left-dot ${(filledDots[1] && filledDots[1].children?.[0]) ? "filled" : ""}`}>
                                            <div className="tooltip">User Name: {filledDots[1]?.children?.[0]?.username || "N/A"}</div>
                                          </div>

                                          <div className={`dot right-right-dot ${(filledDots[1] && filledDots[1].children?.[1]) ? "filled" : ""}`}>
                                            <div className="tooltip">User Name: {filledDots[1]?.children?.[1]?.username || "N/A"}</div>
                                          </div>

                                          {/* Activation Overlay */}
                                          {!isActivated && (
                                            <div className="zone-overlay">
                                              <button
                                                className="activate-btn"
                                                onClick={() => handleActivationModal(zone, rainmakerData[Number(zone.level) - 1], false, index === 0 ? 'both' : 'premium')}
                                              >
                                                Activate Zone
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                                  {isActivationModalOpen && (
                                    <ActivationModal
                                      isOpen={isActivationModalOpen}
                                      onRequestClose={() => {
                                        fetchUserDetails();
                                        setActivationModalOpen(false);
                                      }}
                                      count={activationAmount ?? 0}
                                      isRainmaker={activationIsRainmaker}
                                      zone={activationZone!}
                                      onConfirmActivation={() => {
                                        fetchUserDetails();
                                        setActivationModalOpen(false);
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button"
                              type="button"
                              onClick={() => setIsCollapsedRainmaker(!isCollapsedRainmaker)}
                            >
                              {isCollapsedRainmaker ? "HIDE ZONES" : "RAINMAKER ZONES"}
                            </button>
                          </h2>
                          {isCollapsedRainmaker && (
                            <div className="accordion-collapse">
                              <div className="accordion-body">
                                <div className="row">
                                  {rainmakerData.map((zone, index) => {
                                    const isActivated = (userData.rainmaker_level ? Number(userData.rainmaker_level.split(".")[0]) : 0) >= (index + 1);

                                    // Ensure `childs` is an array before proceeding
                                    const filledDots = Array.isArray(zone.childs) ? zone.childs : [];

                                    return (
                                      <div className="col-md-4" key={zone.level}>
                                        <div className="zonecard">
                                          <h2 className="zonetext">Zone {zone.level}</h2>
                                          <h3 className="dollar">
                                            <img src={require('../../assets/images1/icons/tether.png')} alt="dollar" />
                                            ${index === 0 ? parseFloat(zone.entryFee) + parseFloat(premiumData[index].entryFee) : zone.entryFee}
                                          </h3>
                                          <h2 className="people">
                                            <img src={require('../../assets/images1/icons/users.png')} alt="people" /> {filledDots.length}
                                          </h2>
                                          <h3 className="refresh">
                                            <img src={require('../../assets/images1/icons/refresh.png')} alt="refresh" /> {zone.reentryCount}
                                          </h3>

                                          {/* Middle Dot */}
                                          <div className={`dot middle-dot ${isActivated ? "filled" : ""}`}></div>

                                          {/* Left-Left Dot */}
                                          <div className={`dot left-left-dot ${filledDots[0] ? "filled" : ""}`}>
                                            <div className="tooltip">User Name: {filledDots[0]?.username || "N/A"}</div>
                                          </div>

                                          {/* Left-Right Dot */}
                                          <div className={`dot left-right-dot ${filledDots[1] ? "filled" : ""}`}>
                                            <div className="tooltip">User Name: {filledDots[1]?.username || "N/A"}</div>
                                          </div>

                                          {/* Right-Left Dot */}
                                          <div className={`dot right-left-dot ${filledDots[2] ? "filled" : ""}`}>
                                            <div className="tooltip">User Name: {filledDots[2]?.username || "N/A"}</div>
                                          </div>

                                          {/* Right-Right Dot */}
                                          <div className={`dot right-right-dot ${filledDots[3] ? "filled" : ""}`}>
                                            <div className="tooltip">User Name: {filledDots[3]?.username || "N/A"}</div>
                                          </div>

                                          {/* Activation Overlay */}
                                          {!isActivated && (
                                            <div className="zone-overlay">
                                              <button className="activate-btn" onClick={() => handleActivationModal(zone, premiumData[Number(zone.level) - 1], true, index === 0 ? "both" : "rainmaker")}>
                                                Activate Zone
                                              </button>
                                            </div>
                                          )}

                                          {/* Activation Modal */}
                                          {isActivationModalOpen && (
                                            <ActivationModal
                                              isOpen={isActivationModalOpen}
                                              onRequestClose={() => setActivationModalOpen(false)}
                                              count={activationAmount ?? 0}
                                              isRainmaker={activationIsRainmaker}
                                              zone={activationZone!}
                                              onConfirmActivation={() => {
                                                setActivationModalOpen(false);
                                                setCongratulationsModalOpen(true);
                                                handleShowCongratulationsModal();
                                                Toastify({
                                                  text: 'You have successfully activated the zone',
                                                  duration: 5000,
                                                  gravity: 'top',
                                                  position: 'center',
                                                  backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
                                                }).showToast();
                                              }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          )}

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <div className="container">
      {renderContent()}
      <CongratulationsModal
        isOpen={isCongratulationsModalOpen}
        onRequestClose={handleCloseCongratulationsModal}
      />

      {/* Modals */}
      <DepositModal
        isOpen={isDepositModalOpen}
        onRequestClose={() => setDepositModalOpen(false)}
      // amountFromDB={depositAmount ?? 0}
      // userData={userData} // Pass userData here from the context
      // onConfirmDeposit={handleShowCongratulationsModal} // Pass the function here
      />
      <DepositDetectedModal
        isOpen={isDepositDetectedModalOpen}
        amount={depositDetectedAmount ?? '0'}
        onRequestClose={() => setDepositDetectedModalOpen(false)}
      />
      <WithdrawModal
        isOpen={isWithdrawModalOpen}
        onRequestClose={() => {
          setWithdrawModalOpen(false);
          setIsWithdrawalModalOpen(true);
        }}
        userId={userData?.id ?? ''}
        userBalance={withdrawAmount ?? 0}
        amount={withdrawAmount ?? 0}
        username={userData?.user_name ?? ''}
        userAddress={userData?.wallet_address ?? ''}
        onConfirmWithdraw={() => {
          // Implement withdraw logic here
          setWithdrawModalOpen(false);
        }}
      />
      <WithdrawalCompletionModal isOpen={isWithdrawalModalOpen} onRequestClose={() => setIsWithdrawalModalOpen(false)} />

      <TransferModal
        isOpen={isTransferModalOpen}
        onRequestClose={() => {
          setTransferModalOpen(false);
          fetchUserDetails();
        }}
        // onConfirmTransfer={(toUser, amount) => {
        //   // Implement transfer logic here
        //   console.log(`Transfer to ${toUser} amount: ${amount}`);
        //   setTransferModalOpen(false);
        // }}
        userId={userData?.id ?? ''}
        sponsorId={userData?.sponsor_id ?? ''}
        onConfirmTransfer={() => {
          setTransferModalOpen(false);
          setCongratulationsModalOpen(true);
        }}
        userAddress={userData?.wallet_address ?? ''}
        username={userData?.user_name ?? ''}
        userBalance={Number(walletBalance) ?? 0.0}
        payoutBalance={Number(userData?.payout_wallet) ?? 0.0}
        setTransferAmount={setTransferAmount} // Pass the function
      />
    </div>
  );


};

export default MainContent;