import React, { createContext, useState, useEffect, ReactNode } from 'react';
import Web3 from 'web3';

// Define types
interface Referral {
  _id: string;
  email: string;
  wallet_address: string | null;
  private_key: string;
  created_at: string;
  email_verified: boolean;
  user_name: string;
  sponsor_name: string;
  direct_team: number;
  team_count: number;
  registration_method: string;
  payout_wallet: number;
  premium_upgrade_wallet: number;
  premium_reentry_wallet: number;
  premium_reserves_wallet: number;
  rainmaker_upgrade_wallet: number;
  rainmaker_reentry_wallet: number;
  rainmaker_reserves_wallet: number;
  referrer_address: string;
  sponsor_id: string;
  premium_level: string;
  rainmaker_level: string;
  highest_premium_level: string | null;
  highest_rainmaker_level: string | null;
  is_active: boolean;
  last_activity: string;
  password: string;
  undefined: boolean;
  is_paid: boolean;
}

interface Transaction {
  amount: number;
  type: string;
  created_at: string;
}

interface LevelDetails {
  level: number;
  entry_count: number;
  recycle_count: number;
}

interface UserData {
  id: string;
  email: string;
  wallet_address: string;
  private_key: string | null;
  created_at: string;
  email_verified: boolean;
  user_name: string;
  sponsor_name: string;
  direct_team: number;
  registration_method: string;
  payout_wallet: string;
  premium_upgrade_wallet: string,
  premium_recycle_wallet: string,
  rainmaker_upgrade_wallet: string,
  rainmaker_recycle_wallet: string,
  referrer_address: string;
  sponsor_id: string | null;
  premium_level: string;
  rainmaker_level: string;
  is_paid: boolean;
  direct_referrals: Referral[];
  transactions: Transaction[];
  rainmaker_levels: LevelDetails[];
  premium_levels: LevelDetails[];
  total_users: number;
  new_users_24hrs: number;
  total_earned: number;
  transaction_count: number;
}

interface AuthContextType {
  userData: UserData | null;
  token: string | null;
  login: (data: any) => void;
  logout: () => void;
  setUserData: (data: UserData) => void;
  fetchUserDetailsFromContract: (contract: any, walletAddress: string) => Promise<void>;
}

// Create context
const UserAuthContext = createContext<AuthContextType>({
  userData: null,
  token: null,
  login: () => {},
  logout: () => {},
  setUserData: () => {},
  fetchUserDetailsFromContract: async () => {},
});

export const UserAuthProvider = ({ children }: { children: ReactNode }) => {
  const [userData, setUserDataState] = useState<UserData | null>(null);
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
  
  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    const storedToken = localStorage.getItem('token');
    console.log('Stored user data:', storedUserData);
    console.log('Stored token:', storedToken);
    if (storedUserData) {
      console.log('Restoring user data from localStorage');
      try {
        setUserDataState(JSON.parse(storedUserData));
        setToken(storedToken);
      } catch (error) {
        console.error('Error parsing stored user data:', error);
        localStorage.removeItem('userData'); // Remove corrupted data
      }
    }
  }, []);
  
  useEffect(() => {
    if (userData) {
      console.log('Updating localStorage with userData');
      localStorage.setItem('userData', JSON.stringify(userData));
    } else {
      console.log('User data is null, clearing localStorage');
      localStorage.removeItem('userData');
    }
  }, [userData]);
  
  const login = (data: any) => {
    if (!data || !data.user_details) {
      console.error('Invalid data structure:', data);
      return;
    }
  
    const {
      _id,
      email,
      wallet_address,
      private_key,
      created_at,
      email_verified,
      user_name,
      sponsor_name,
      sponsor_id,
      direct_team,
      registration_method,
      payout_wallet,
      premium_upgrade_wallet,
      premium_recycle_wallet,
      rainmaker_upgrade_wallet,
      rainmaker_recycle_wallet,
      referrer_address,
      premium_level,
      rainmaker_level,
      total_users,
      new_users_24hrs,
      is_paid,
      total_earned,
      transaction_count,
  } = data.user_details;
  
    const newUserData: UserData = {
      id: _id.toString(),
      email,
      wallet_address,
      private_key,
      created_at,
      email_verified: Boolean(email_verified),
      user_name,
      sponsor_name,
      sponsor_id: sponsor_id ? sponsor_id.toString() : null,
      direct_team,
      registration_method,
      payout_wallet,
      premium_upgrade_wallet,
      premium_recycle_wallet,
      rainmaker_upgrade_wallet,
      rainmaker_recycle_wallet,
      referrer_address,
      premium_level,
      rainmaker_level,
      total_users,
      new_users_24hrs,
      is_paid,
      total_earned,
      transaction_count,
      direct_referrals: data.direct_referrals || [],
      transactions: data.transactions || [],
      rainmaker_levels: data.rainmaker_levels || [],
      premium_levels: data.premium_levels || [],
    };
  
    // Save user data and token
    console.log('Setting user data:', newUserData);
    console.log('Setting token:', data.token);
    setUserDataState(newUserData);
    if(data.token) {
    setToken(data.token || '');
  
    localStorage.setItem('token', data.token || '');
    }
    // Store in localStorage for persistence
    localStorage.setItem('userData', JSON.stringify(newUserData));
  };

  const logout = () => {
    console.log('Logging out');
    setUserDataState(null);
    setToken(null);
    localStorage.clear();
  };

  const fetchUserDetailsFromContract = async (contract: any, walletAddress: string) => {
    try {
      const userDetails = await contract.methods.users(walletAddress).call();
      console.log('Fetched User Details:', userDetails);
    } catch (error) {
      console.error('Error fetching user details from contract:', error);
    }
  };

  return (
    <UserAuthContext.Provider
      value={{
        userData,
        token,
        login,
        logout,
        setUserData: setUserDataState,
        fetchUserDetailsFromContract,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

export const useUserAuth = () => React.useContext(UserAuthContext);

export default UserAuthContext;