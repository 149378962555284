import React, { useState, useEffect } from 'react';
import './Header.css';
import logo from '../assets/images/logo.png'; // Adjust the path based on your project structure
import { Navigate, useNavigate } from 'react-router-dom';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const sponsorName = params.get('referral') ?? '';
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  useEffect(() => {
    const loginParam = params.get('login');
    const registerParam = params.get('register');

    if (loginParam === 'true') {
      setShowLoginModal(true);
    }

    if (registerParam === 'true') {
      setShowRegisterModal(true);
    }
  }, [params]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleOpenLoginModal = () => {
    // setShowLoginModal(true);
    navigate('/login');

  };

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
  };

  const handleOpenRegisterModal = () => {
    // setShowRegisterModal(true);
    navigate('/register');
  };

  const handleCloseRegisterModal = () => {
    setShowRegisterModal(false);
  };

  return (
    <header className="hero-container">
      <div className="logo-container">
        <img src={logo} alt="Smart Rainmakers Logo" className="logo header-logo" />
        <div className="hamburger-menu" onClick={toggleMenu}>
          &#9776; {/* Hamburger icon */}
        </div>
      </div>
      <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
        <div className="nav-box">
          <a href="./" className="nav-item">Home</a>
          <a href="#about" className="nav-item">About</a>
          <a href="#user-exclusive-section" className="nav-item">Get Started</a>
          <a href="#FAQ" className="nav-item">FAQ</a>
          <a href="#global-givers-fund" className="nav-item">Global Givers Fund</a>
        </div>
      </nav>
      <div className={`action-buttons ${isMenuOpen ? 'open' : ''}`}>
        <div className='login-btn-div'>
        <button className="button" onClick={handleOpenLoginModal}>Login</button>
        <button className="button register" onClick={handleOpenRegisterModal}>Register</button>
        </div>
        {/* <button className="button connect-wallet">Connect Wallet</button> */}
      </div>
    </header>
  );
};

export default Header;
