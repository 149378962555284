export async function fetchBNBPrice() {
    try {
        const response = await fetch('https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT');
        const data = await response.json();
        return parseFloat(data.price);
    } catch (error) {
        console.error('Error fetching BNB price:', error);
        return null;
    }
}

export async function convertUsdToBnb(amountInUsd) {
    const bnbPrice = await fetchBNBPrice();
    if (!bnbPrice) {
        alert("Failed to fetch BNB price. Try again later.");
        return null;
    }
    return amountInUsd / bnbPrice;  // Convert USD to BNB
}
